import React from "react";
import { ContainerLayout } from "../../../Common/Layouts/ContainerLayout/ContainerLayout";
import { AppLayout } from "../../../Common/Layouts/AppLayout/AppLayout";

export function HomeLayout() {
    return (
        <AppLayout>
            <ContainerLayout />
        </AppLayout>
    )
}