import { LiveStreamAction } from "../Actions/LiveStream.action";
import { ILiveStreamState } from "../States/LiveStream.state";

const initialState: ILiveStreamState = {
    roomId: "",
    title: "",
    description: "",
    private: false,
    mutedViewers: true,
    streamingStarted: false
};

export function LiveStreamReducer(state = initialState, action: any) {

    let nextState: ILiveStreamState;

    switch (action.type) {
        case LiveStreamAction.SET_LIVE_STREAM_DETAILS:
            nextState = {
                ...state,
                ...action.value
            }
            return nextState || state;
        case LiveStreamAction.SET_LIVE_STREAM_STARTED:
            nextState = {
                ...state,
                streamingStarted: true
            }
            return nextState || state;
        case LiveStreamAction.SET_LIVE_STREAM_STOPED:
            nextState = {
                ...state,
                streamingStarted: false
            }
            return nextState || state;
        default:
            return state;
    }
}