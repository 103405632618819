import React from "react";
import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { IPaginationModel } from "../../Common/Models/Pagination.model";
import { IFileSignResponse } from "../Responses/IFileSign.response";
import { axiosErrorHandler } from "../../Common/Handlers/axios.handler";
import { IFilePrivacyRequest } from "../Requests/IFilePrivacy.request";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks/Hooks";
import { FileSignAction } from "../Redux/Actions/FileSign.action";
import { FileDownloaderService } from "./FileDownloader.service";
import { FileSignUiAction } from "../Redux/Actions/FileUi.action";
import { IHttpResponseModel } from "../../Common/Models/HttpResponse.model";
import { api } from "../Services.api";

const http = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_CORE,
    headers: {
        "Authorization": `Bearer ${Cookies.get("stockraken-jwt")}`
    }
});

export class FileSignService {

    public static getFiles(currentPage: number) {
        return new Promise<IPaginationModel<IFileSignResponse>>((resolve, reject) => {
            http.get<IPaginationModel<IFileSignResponse>>(`${api.core.GetSignFiles}/${currentPage}`).then(result => {
                resolve(result.data);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public static getFile(id: string) {
        return new Promise<AxiosResponse<IFileSignResponse>>((resolve, reject) => {
            http.get<IFileSignResponse>(`${api.core.GetSignFileById}/${id}`).then(result => {
                resolve(result);
            }).catch((error: any) => {
                axiosErrorHandler(error);
                reject(error);
            });
        });
    }

    public static signFiles(ids: Array<string>) {
        return new Promise<Array<IFileSignResponse>>((resolve, reject) => {
            http.post<IHttpResponseModel<Array<IFileSignResponse>>>(api.core.SignFiles, { "ids": ids },
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(result => {
                    resolve(result.data.data);
                }).catch((error: any) => {
                    axiosErrorHandler(error);
                });
        });
    }

    public static DownloadFile(id: string) {
        return new Promise<AxiosResponse<Blob>>((resolve, reject) => {
            http.get<Blob>(`${api.core.DownloadSignFile}/${id}`, { responseType: "blob" }).then(result => {
                resolve(result);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public static updateFilePrivacy(id: string, request: IFilePrivacyRequest) {
        return new Promise<AxiosResponse>((resolve, reject) => {
            http.put<AxiosResponse>(`${api.core.UpdateSignFilePrivacy}/${id}`, request).then(result => {
                resolve(result);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public static removeFiles(ids: Array<string>) {
        return new Promise<AxiosResponse>((resolve, reject) => {
            http.post(`${api.core.RemoveSignFiles}`, { "ids": ids }).then(result => {
                resolve(result);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }
}

export function useFileSignService(loadFiles: boolean = false) {

    const dispatch = useAppDispatch();
    const { fileSigned, httpStatus } = useAppSelector(state => state.fileSignReducer);

    const getFiles = (currentPage: number) => {
        FileSignService.getFiles(currentPage).then(result => {
            dispatch({ type: FileSignAction.SET_FILES, value: result });
        });
    }

    const signFiles = (ids: Array<string>) => {
        return new Promise<Array<IFileSignResponse>>((resolve, reject) => {
            FileSignService.signFiles(ids).then(result => {
                dispatch({ type: FileSignAction.UPDATE_FILES, value: result });
                dispatch({ type: FileSignAction.SET_SELECTED_FILES, value: [] });
                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    }

    const downloadFile = (id: string) => {
        FileSignService.DownloadFile(id).then(result => {
            FileDownloaderService.setupDownload(result);
            dispatch({ type: FileSignAction.SET_SELECTED_FILES, value: [] });
        });
    }

    const getFileById = (id: string) => {
        FileSignService.getFile(id).then(result => {
            dispatch({
                type: FileSignAction.SET_DOWNLOAD_FILE, value: {
                    fileSigned: result.data,
                    httpStatus: result.status
                }
            });
        }).catch(error => {
            dispatch({
                type: FileSignAction.SET_DOWNLOAD_FILE, value: {
                    fileSigned: null,
                    httpStatus: error.response.status
                }
            });
        });
    }

    const updateFilePrivacy = (id: string, request: IFilePrivacyRequest): void => {
        FileSignService.updateFilePrivacy(id, request).then(() => {
            dispatch({ type: FileSignAction.UPDATE_FILE_PRIVACY, value: { "id": id, "filePrivacyId": request.filePrivacyId } });
        });
    }

    const removeFiles = (ids: Array<string>) => {
        return new Promise<void>((resolve, reject) => {
            FileSignService.removeFiles(ids).then(() => {
                dispatch({ type: FileSignAction.REMOVE_FILES, value: ids });
                dispatch({ type: FileSignUiAction.TOGGLE_FILE_REMOVE_MODAL, value: false });
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    return { fileSigned, httpStatus, getFiles, getFileById, signFiles, downloadFile, removeFiles, updateFilePrivacy } as const
}

