export enum FileSignAction {
    SET_CURRENT_FILE_ID = "SET_CURRENT_SIGN_FILE_ID",
    SET_CURRENT_FILE_PRVIACY_ID = "SET_CURRENT_SIGN_FILE_PRVIACY_ID",
    SET_DOWNLOAD_FILE = "SET_DOWNLOAD_SIGN_FILE",
    SET_FILES = "SET_SIGN_FILES",
    UPDATE_FILE_PRIVACY = "UPDATE_SIGN_FILE_PRIVACY",
    REMOVE_FILES = "REMOVE_SIGN_FILES",
    UPDATE_PAGINATION = "UPDATE_SIGN_FILE_PAGINATION",
    SET_SELECTED_FILES = "SET_SELECTED_SIGN_FILES",
    UPDATE_FILES = "UPDATE_SIGN_FILES",
}