import React from "react";
import styles from "./FileTableWrapper.module.scss";
import { Button, Icon } from "@stockraken/ui";
import { IFileTableWrapperProps } from "../../Props/File.props";

export function FileTableWrapper({ getNext, getPrevious, currentPage, maxPages, nextDisabled, previousDisabled, children }: IFileTableWrapperProps) {

    return (
        <React.Fragment>
            {
                maxPages !== 0 &&
                <div className={styles["file-table-wrapper"]}>
                    <div className={styles["file-table-wrapper-body"]}>
                        {children}
                    </div>
                    <div className={styles["file-table-wrapper-footer"]}>
                        <div className={styles["file-table-wrapper-navigation"]}>
                            <Button color="ghost" onClick={getPrevious} disabled={previousDisabled}>
                                <Icon name="icon-minus" width="13px" height="13px" color="#7c3aed" />
                            </Button>
                            <label>Pages : {currentPage + 1} | {maxPages}</label>
                            <Button color="ghost" onClick={getNext} disabled={nextDisabled}>
                                <Icon name="icon-plus" width="13px" height="13px" color="#7c3aed" />
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}