import { ReportBugAction } from "../Actions/ReportBug.action";
import { IReportBugState } from "../States/ReportBug.state";

const initialState: IReportBugState = {
    bugTypes: null
};

export function ReportBugReducer(state = initialState, action: any ){
    
    let nextState: IReportBugState;

    switch (action.type) {
        case ReportBugAction.SET_BUG_TYPES:
            nextState = {
                ...state,
                bugTypes: action.value
            }
            return nextState || state;
        default:
            return state;
    }
}