import React from "react";
import { useLocation, } from "react-router-dom";
import { topMenuToolbarGeneratorMapping } from "./TopMenuToolbarGenerator.mapping";
import styles from "./TopMenuToolbarGenerator.module.scss";

export function TopMenuToolbarGenerator() {
    const { pathname } = useLocation();
    const toolbar = topMenuToolbarGeneratorMapping.find(item => item.pathname === pathname.replace("/", ""));

    return (
        <div className={styles["top-menu-toolbar-generator"]}>
            {
                toolbar &&
                React.createElement(toolbar.component, { ...toolbar.props })
            }
        </div>
    )
}