import { ISigninState } from "../../States/Signin.state";
import { SigninAction } from "../Actions/Signin.action";

const initialState: ISigninState = {
    response: {
        message: "",
        statusCode: 0,
        data: null
    },
    request: {
        username: "",
        password: ""
    }
};

export function signinReducer(state = initialState, action: any) {

    let nextState: ISigninState;

    switch (action.type) {
        case SigninAction.UPDATE_DATA_RESPONSE:
            nextState = {
                ...state,
                response: action.value
            }
            return nextState || state;
        case SigninAction.UPDATE_DATA_REQUEST:
            nextState = {
                ...state,
                request: { ...state.request, ...action.value }
            }
            return nextState || state;
        default:
            return state;
    }
}