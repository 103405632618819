import React from "react";
import styles from "./Video.module.scss";
import Hls from "hls.js";
import { Icon } from "@stockraken/ui";
import { IHTMLButtonElement } from "../../../Common/Types/HTMLElement";
import { IIconProps } from "@stockraken/ui/props";
import { IHTMLButtonEvent } from "../../../Common/Types/HTMLEvent";
import i18next from "i18next";

interface IHTMLVideoElement extends React.VideoHTMLAttributes<HTMLVideoElement> {
}

interface IVideoStreamingProps extends IHTMLVideoElement {
    url: string;
}

interface IVideoStreamingButtonToolProps extends IHTMLButtonElement {
    active: boolean;
    activeIcon: IIconProps;
    inactiveIcon?: IIconProps;
}

export function VideoStreaming({ url, poster, muted = true, autoPlay = true, ...html }: IVideoStreamingProps) {

    const videoRef = React.useRef<HTMLVideoElement>(null);
    const hls = React.useRef<Hls>(new Hls());
    const [streamingLoaded, setStreamingLoaded] = React.useState<boolean>(false);
    const [playButtonActive, setPlayButtonActive] = React.useState<boolean>(true);
    const [volumeButtonActive, setVolumeButtonActive] = React.useState<boolean>(false);
    const [fullScreenButtonActive, setFullScreenButton] = React.useState<boolean>(false);

    const onClickPlayButton = (event: IHTMLButtonEvent) => setPlayButtonActive(!playButtonActive);
    const onClickVolumeButton = (event: IHTMLButtonEvent) => setVolumeButtonActive(!volumeButtonActive);
    const onClickFullScreenButton = (event: IHTMLButtonEvent) => setFullScreenButton(!fullScreenButtonActive);

    const playIconRef = React.useRef<IIconProps>({
        name: "icon-play-solid",
        width: "21px",
        height: "18px",
        color: "#f3f4f6"
    });

    const pauseIconRef = React.useRef<IIconProps>({
        name: "icon-pause-solid",
        width: "21px",
        height: "18px",
        color: "#f3f4f6"
    });

    const volumeIconRef = React.useRef<IIconProps>({
        name: "icon-volume",
        width: "21px",
        height: "19px",
        color: "#f3f4f6"
    });

    const volumeMuteIconRef = React.useRef<IIconProps>({
        name: "icon-volume-mute",
        width: "21px",
        height: "19px",
        color: "#f3f4f6"
    });

    const fullScreenIconRef = React.useRef<IIconProps>({
        name: "icon-full-screen",
        width: "21px",
        height: "18px",
        color: "#f3f4f6"
    });


    React.useEffect(() => {
        if (url) {
            hls.current.loadSource(url);
            hls.current.on(Hls.Events.MANIFEST_PARSED, function () {
                setStreamingLoaded(true);
            });
        }
    }, [url]);

    React.useEffect(() => {
        if (streamingLoaded && videoRef.current) {
            hls.current.attachMedia(videoRef.current);
            videoRef.current.play();
        }
    }, [streamingLoaded]);

    return (
        <div className={styles["video"]}>
            <video
                {...html}
                ref={videoRef}
                autoPlay={autoPlay}
                muted={muted}
                poster={poster}
            />
            <div className={styles["video-tools"]}>
                <div className={styles["video-tools-left"]}>
                    <ButtonTool
                        active={playButtonActive}
                        onClick={onClickPlayButton}
                        activeIcon={playIconRef.current}
                        inactiveIcon={pauseIconRef.current} />
                    <ButtonTool
                        active={volumeButtonActive}
                        onClick={onClickVolumeButton}
                        activeIcon={volumeIconRef.current}
                        inactiveIcon={volumeMuteIconRef.current} />
                </div>
                <div className={styles["video-tools-right"]}>
                    <StreamingLiveBadge />
                    <ButtonTool
                        active={fullScreenButtonActive}
                        onClick={onClickFullScreenButton}
                        activeIcon={fullScreenIconRef.current} />
                </div>
            </div>
        </div>
    )
}

const ButtonTool = ({ active, activeIcon, inactiveIcon, onClick }: IVideoStreamingButtonToolProps) => {
    return (
        <button type="button" onClick={onClick}>
            <Icon {...((!active && inactiveIcon) ? inactiveIcon : activeIcon)} />
        </button>
    )
}

const StreamingLiveBadge = () => {
    return (
        <span className="relative flex h-5 w-4 items-center justify-center">
            <span className="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-red-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
    )
}