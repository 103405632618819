import React from "react";
import { Link } from "react-router-dom";
import styles from "./SigninForm.module.scss";
import { SigningActivation } from "../SigninActivation/SigningActivation.component";
import { SigninLocked } from "../SigninLocked/SigninLocked.component";
import { SigninAuthenticator } from "../SigninAuthenticator/SigninAuthenticator.component";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { SigninAction } from "../../Redux/Actions/Signin.action";
import { UserService } from "../../Services/User.service";
import { Button, Form, FieldGroup, Input, Icon } from "@stockraken/ui";
import { HttpStatus as HttpStatusCode } from "@stockraken/ui/enumerations";
import { IHTMLFormEvent, IHTMLInputEvent, IKeyValue } from "@stockraken/ui/types";

export function SigninForm() {

    const dispatch = useAppDispatch();
    const [showPassword] = React.useState<Boolean | null>(null);
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const { request, response } = useAppSelector(state => state.signinReducer);
    const passwordRef = React.useRef<HTMLInputElement>(null);

    const handleChange = (event: IHTMLInputEvent) => {
        let credentials: IKeyValue<string> = {};
        credentials[event.target.name] = event.target.value;

        dispatch({
            "type": SigninAction.UPDATE_DATA_REQUEST, "value": credentials
        });
    }

    const handleSubmit = (event: IHTMLFormEvent) => {
        event.preventDefault();
        if (event.target.checkValidity()) {
            setDisabled(true);
            UserService.authenticate(request).then(result => {
                if (result.statusCode !== HttpStatusCode.Status200OK) {
                    setDisabled(false);
                }
                dispatch({ "type": SigninAction.UPDATE_DATA_RESPONSE, "value": result });
            });
        }
    }

    React.useEffect(() => {
        if (showPassword !== null) {
            passwordRef.current!.focus();
        }
    }, [showPassword]);

    React.useEffect(() => {
        if (response.statusCode === HttpStatusCode.Status200OK || response.statusCode === HttpStatusCode.Status208AlreadyReported) {
            setErrorMessage(null);
        } else if (response.statusCode === HttpStatusCode.Status400BadRequest) {
            setErrorMessage(response.message);
            setDisabled(false);
        } else {
        }
    }, [response]);

    return (
        <div className={styles["signin-form-container"]}>
            <Form onSubmit={handleSubmit} className={`${styles["signin-form"]} ${styles["smoked-theme-app"]}`} message={errorMessage} messagePosition="bottom">
                {
                    response.statusCode === HttpStatusCode.Status201Created && response.data &&
                    <SigningActivation
                        firstName={response.data.userInformation.firstName}
                        lastName={response.data.userInformation.lastName}
                        email={response.data.email}
                        message={response.message}
                        id={response.data.id} />
                }
                {
                    response.statusCode === HttpStatusCode.Status423Locked
                    && response.data
                    && response.data.userLocked &&
                    <SigninLocked
                        reason={response.data.userLocked[0].reason}
                        message={response.message}
                        startTime={response.data.userLocked[0].startTime}
                        endTime={response.data.userLocked[0].endTime} />
                }
                {
                    response.statusCode === 0 &&
                    <React.Fragment>
                        <h1>Welcome back !</h1>
                        <h2>Remember to never share your credentials with others!</h2>
                        <FieldGroup>
                            <label>Username</label>
                            <Input
                                type="text"
                                placeholder={"example@domain.com"}
                                autoFocus={true}
                                required={true}
                                minLength={5}
                                maxLength={50}
                                name="username"
                                value={request.username}
                                onChange={handleChange} />
                        </FieldGroup>
                        <FieldGroup className="!mb-4">
                            <label>Password</label>
                            {
                                showPassword &&
                                <Input
                                    ref={passwordRef}
                                    type="text"
                                    placeholder={"Password"}
                                    required={true}
                                    minLength={6}
                                    maxLength={25}
                                    name="password"
                                    value={request.password}
                                    onChange={handleChange} />
                            }
                            {
                                !showPassword &&
                                <Input
                                    ref={passwordRef}
                                    type={"password"}
                                    placeholder={"Password"}
                                    required={true}
                                    minLength={6}
                                    maxLength={25}
                                    name="password"
                                    value={request.password}
                                    onChange={handleChange} />
                            }
                        </FieldGroup>
                        <div className={styles["signin-password-recovery-link"]}>
                            <Link to="/signin">Forgot your password ?</Link>
                        </div>
                        <Button type={"submit"} hasSpinner disabled={disabled}>
                            {disabled ? <>Connecting..</> : <>Log In</>}
                        </Button>
                        <div className={styles["signin-register-link"]}>
                            <h6>Not a member ? <Link to="/signin">Register now</Link></h6>
                        </div>
                    </React.Fragment>
                }
                {
                    response.statusCode === HttpStatusCode.Status208AlreadyReported && response.data && response.data.userAuthenticator &&
                    <SigninAuthenticator
                        message={response.message}
                        authenticationCode={response.data.userAuthenticator.authenticationCode}
                        userId={response.data.id}
                    />

                }
            </Form>
        </div>

    )
}
