import { IAppState } from "../States/App.state";
import { AppAction } from "../Actions/App.action";

const initialState: IAppState = {
    darkMode: false,
    showNotificationList: false,
    showReportBugModal: false
};

export function appReducer(state = initialState, action: any) {

    let nextState: IAppState;

    switch (action.type) {
        case AppAction.TOGGLE_DARKMODE:
            nextState = {
                ...state,
                darkMode: action.value
            }
            return nextState || state;
        case AppAction.TOGGLE_NOTIFICATION_LIST:
            nextState = {
                ...state,
                showNotificationList: !state.showNotificationList
            }
            return nextState || state;
        case AppAction.TOGGLE_REPORT_BUG_MODAL:
            nextState = {
                ...state,
                showReportBugModal: action.value || !state.showReportBugModal
            }
            return nextState || state;
        default:
            return state;
    }
}