import React from "react";
import styles from "./FileToolbar.module.scss";
import { Button, Icon } from "@stockraken/ui";
import { IHTMLButtonEvent } from "@stockraken/ui/types";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { FilePrivacyUiAction, FileSignUiAction, FileUploadUiAction } from "../../Redux/Actions/FileUi.action";
import { useFileUploadService } from "../../Services/FileUpload.service";
import { useFileSignService } from "../../Services/FileSign.service";
import { FileToolbarProps } from "../../Props/FileToolbar.props";
import { FileModules } from "../../Enumerations/FileModules.enum";
import { FileToolbarType } from "../../Types/FileToolbar";
import { Tooltip } from "@stockraken/ui";

export function FileToolbar({ module }: FileToolbarProps) {

    const fileUploadService = useFileUploadService();
    const fileSignService = useFileSignService();

    const dispatch = useAppDispatch();

    const { selectedFiles } = useAppSelector(state => {
        switch (module) {
            case FileModules.FILE_UPLOAD:
                return state.fileUploadReducer;
            case FileModules.FILE_SIGN:
                return state.fileSignReducer;
            default:
                throw Error("Missing Module propos in FileToolbar Component");
        }
    });

    const isDisabledSingleFile: boolean = selectedFiles.length === 0 || selectedFiles.length > 1;
    const isDisabledMultipleFiles: boolean = selectedFiles.length === 0;

    const onShowFileUploadModal = (event: IHTMLButtonEvent) => {
        dispatch({ "type": FileUploadUiAction.TOGGLE_FILE_UPLOAD_MODAL, "value": true });
    }

    const onShowFileRemoveModal = (event: IHTMLButtonEvent) => {
        switch (module) {
            case FileModules.FILE_UPLOAD:
                dispatch({ type: FileUploadUiAction.TOGGLE_FILE_REMOVE_MODAL, value: true });
                break;
            case FileModules.FILE_SIGN:
                dispatch({ type: FileSignUiAction.TOGGLE_FILE_REMOVE_MODAL, value: true });
                break;
            default:
                break;
        }
    }

    const onShowFilePrivacyModal = (event: IHTMLButtonEvent) => {
        dispatch({ type: FilePrivacyUiAction.TOGGLE_FILE_PRIVACY_MODAL, value: true });
    }

    const onSigneFiles = (event: IHTMLButtonEvent) => {
        if (selectedFiles.length > 0) {
            dispatch({ "type": FileUploadUiAction.TOGGLE_FILE_SIGN_MODAL, "value": true });
        }
    }

    const onDownloadFile = (event: IHTMLButtonEvent) => {
        if (selectedFiles.length === 1) {
            switch (module) {
                case FileModules.FILE_UPLOAD:
                    fileUploadService.downloadFile(selectedFiles[0]);
                    break;
                case FileModules.FILE_SIGN:
                    fileSignService.downloadFile(selectedFiles[0]);
                    break;
                default:
                    break;
            }
        }
    }

    const onCopyLinkFile = (event: IHTMLButtonEvent) => {
        if (selectedFiles.length === 1) {
            const url = `${process.env.REACT_APP_ENDPOINT_FILE_SIGN_VERIFICATION}/${selectedFiles[0]}`;
            navigator.clipboard.writeText(url);
        }
    }

    const tools: Array<FileToolbarType> = [
        { id: "1", icon: "icon-qr-code", description: "Sign File", disabled: isDisabledMultipleFiles, onClick: onSigneFiles, modules: [FileModules.FILE_UPLOAD] },
        { id: "2", icon: "icon-world", description: "Edit File Privacy", disabled: isDisabledSingleFile, onClick: onShowFilePrivacyModal, modules: [FileModules.FILE_SIGN] },
        { id: "3", icon: "icon-link", description: "Copy File Link", disabled: isDisabledSingleFile, onClick: onCopyLinkFile, modules: [FileModules.FILE_SIGN] },
        { id: "4", icon: "icon-trash", description: "Delete File", disabled: isDisabledMultipleFiles, onClick: onShowFileRemoveModal },
        { id: "5", icon: "icon-download", description: "Download File", disabled: isDisabledSingleFile, onClick: onDownloadFile },
        { id: "6", icon: "icon-upload", description: "Upload File", disabled: false, onClick: onShowFileUploadModal, modules: [FileModules.FILE_UPLOAD] },
    ]

    return (
        <div className={styles["file-toolbar"]}>
            {
                tools.map((item, index) => (
                    (item.modules == null || item.modules.includes(module)) &&
                    <Tooltip key={index} text={item.description}>
                        <Button key={item.id} onClick={item.onClick} disabled={item.disabled} type="button" color="ghost">
                            <Icon name={item.icon} color="#333" width="15px" height="15px" />
                        </Button>
                    </Tooltip>
                ))
            }
        </div>
    )
}