import { FileToolbar } from "../../../Core/Components/FileToolbar/FileToolbar.component"
import { FileModules } from "../../../Core/Enumerations/FileModules.enum"

export const topMenuToolbarGeneratorMapping = [
    {
        pathname: "uploaded-files",
        component: FileToolbar,
        props: {
            module: FileModules.FILE_UPLOAD
        }
    },
    {
        pathname: "signed-files",
        component: FileToolbar,
        props: {
            module: FileModules.FILE_SIGN
        }
    }
];