import { useEffect, useState } from "react";
import axios from "axios";
import { ICountryResponse } from "../Responses/ICountry.response";
import { api } from "../Services.api";
import { axiosErrorHandler } from "../../Common/Handlers/axios.handler";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks/Hooks";
import { CountryAction } from "../Redux/Actions/Country.action";

const http = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_CORE
});

class CountryService {
    public static getAllCountries() {
        return new Promise<Array<ICountryResponse>>((resolve, reject) => {
            http.get<Array<ICountryResponse>>(api.core.GetCountries).then(result => {
                resolve(result.data);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public static getAllStatesByIso(iso: string) {
        return new Promise<ICountryResponse>((resolve, reject) => {
            http.get<ICountryResponse>(`${api.core.GetCountryStatesByIso}/${iso}`).then(result => {
                resolve(result.data);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }
}

export function useCountries() {
    const dispatch = useAppDispatch();
    const { countries } = useAppSelector(state => state.countryReducer);

    useEffect(() => {
        if (countries === null) {
            CountryService.getAllCountries().then(result => {
                dispatch({ type: CountryAction.UPDATE_COUNTRIES, value: result });
            });
        }
    }, [countries, dispatch]);

    return [countries]
}

export function useCountryStates() {
    const [iso, setIso] = useState<string | null>(null);
    const [countryStates, setCountryStates] = useState<ICountryResponse | null>(null);
    const dispatch = useAppDispatch();
    const { countriesStates } = useAppSelector(state => state.countryReducer);

    useEffect(() => {
        if ((countriesStates === null || !countriesStates?.find(item => item.iso === iso)) && iso) {
            setCountryStates(null);
            CountryService.getAllStatesByIso(iso).then(result => {
                dispatch({ type: CountryAction.UPDATE_STATES, value: result });
                setCountryStates(result);
            });
        }
    }, [countriesStates, iso, dispatch]);


    return [countryStates, setIso] as const;
}