import React from "react";
import styles from "./Signin.module.scss";
import { SigninForm } from "../../Components/SigninForm/SigninForm.component";
import { SigninAnimation } from "../../Components/SigninAnimation/SigninAnimation.component";
import { AuthenticationRouterLayout } from "../../Layouts/AuthenticationRouterLayout/AuthenticationRouter.layout";

export function SigninPage() {
    return (
        <AuthenticationRouterLayout.Auth>
            <div className={styles["signin-page-app"]}>
                <div className={styles["signin-page-body"]}>
                    <SigninForm />
                    <SigninAnimation />
                </div>
                <div className={styles["signin-page-footer"]}>
                    <div>Developed by <a href="https://www.stockraken.com" target="_blank" rel="noopener noreferrer">StocKraken.com</a></div>
                </div>
            </div>
        </AuthenticationRouterLayout.Auth>
    )
}