import React from "react";
import styles from "./TopMenuSearchGenerator.module.scss";
import { Icon } from "@stockraken/ui";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { IHTMLInputEvent } from "@stockraken/ui/types";
import { FileUiAction } from "../../../Core/Redux/Actions/FileUi.action";

export function TopMenuSearchGenerator() {
    const dispatch = useAppDispatch();
    const { search } = useAppSelector(state => state.fileUiReducer);

    const onSearch = (event: IHTMLInputEvent) => {
        dispatch({ type: FileUiAction.SET_SEARCH, value: event.target.value });
    }

    return (
        <div className={styles["top-menu-search-generator"]}>
            <Icon name="icon-search" width="20px" height="20px" />
            <input
                type="text"
                placeholder="Search files.."
                maxLength={50}
                value={search}
                onChange={onSearch} />
        </div>
    )
}