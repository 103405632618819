import React from "react";
import { IUserIconLetterProps } from "../../Props/UserIconLetter.props";
import styles from "./UserIconLetter.module.scss";

export function UserIconLetter({ firstName, lastName }: IUserIconLetterProps) {

    const letters = React.useMemo(() => {
        return {
            firstName: firstName.charAt(0),
            lastName: lastName.charAt(0)
        }
    }, [firstName, lastName]);

    return (
        <div className={styles["user-icon-letter"]}>
            <span>{letters.firstName}{letters.lastName}</span>
        </div>
    )
}