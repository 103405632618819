import axios from "axios";
import Cookies from "js-cookie";
import { IBugTypeResponse } from "../Responses/BugType.response";
import { api } from "../Services.api";
import { axiosErrorHandler } from "../Handlers/axios.handler";

const http = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_CORE,
    headers: {
        "Authorization": `Bearer ${Cookies.get("stockraken-jwt")}`
    }
});

export class BugTypeService {
    public static getBugTypes() {
        return new Promise<Array<IBugTypeResponse>>((resolve, reject) => {
            http.get<Array<IBugTypeResponse>>(api.common.BugType).then(result => {
                if (result.data.length > 0) {
                    resolve(result.data);
                }
            }).catch((error: any) => {
                axiosErrorHandler(error);
                reject(error);
            });
        });
    }
}