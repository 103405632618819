import React, { useEffect } from "react";
import { ProgressBar } from "@stockraken/ui";
import styles from "./FileUploadProgress.module.scss";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { FileUploadUiAction } from "../../Redux/Actions/FileUi.action";

export function UploadProgress() {

    const dispatch = useAppDispatch();
    const { progress } = useAppSelector(state => state.fileUiReducer);

    useEffect(() => {
        if (progress === 100) {
            dispatch({ type: FileUploadUiAction.UPDATE_PROGRESS, value: 0 });
        }
    }, [progress, dispatch])

    return (
        <React.Fragment>
            {
                progress > 0 &&
                <div className={styles["upload-progress"]}>
                    <ProgressBar progress={progress || 0} heightKey="sm" />
                </div>
            }
        </React.Fragment>
    )
}