import React from "react";
import { IHTMLButtonEvent } from "@stockraken/ui/types";
import { Button, Modal } from "@stockraken/ui";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { FileUploadAction } from "../../Redux/Actions/FileUpload.action";
import { FileUploadUiAction } from "../../Redux/Actions/FileUi.action";
import { useFileUploadService } from "../../Services/FileUpload.service";
import styles from "./FileUploadRemoveModal.module.scss"
import i18next from "i18next";

export function FileUploadRemoveModal() {

    const dispatch = useAppDispatch();
    const { showFileUploadRemoveModal } = useAppSelector(state => state.fileUiReducer);
    const { selectedFiles } = useAppSelector(state => state.fileUploadReducer);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { removeFiles: removeFile } = useFileUploadService();

    const onCloseModal = (event: IHTMLButtonEvent) => {
        dispatch({ type: FileUploadUiAction.TOGGLE_FILE_REMOVE_MODAL, value: false });
        dispatch({ type: FileUploadAction.SET_CURRENT_FILE_ID, value: null });
    }

    const onRemoveFile = (event: IHTMLButtonEvent) => {
        if (selectedFiles.length > 0) {
            setIsLoading(true);
            removeFile(selectedFiles).then(() => {
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            });
        }
    }

    return (
        <Modal size="sm" className={styles["file-upload-remove-modal"]} disposeOnClose={false} show={showFileUploadRemoveModal} onClose={onCloseModal}>
            <Modal.Header>
                <h6>{i18next.t("translate-delete-file", { count: selectedFiles.length })}</h6>
            </Modal.Header>
            <Modal.Body>
                <p>{i18next.t("translate-delete-file-question", { count: selectedFiles.length })}</p>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex">
                    <Button type="button" onClick={onRemoveFile} disabled={isLoading} hasSpinner className="ml-auto w-20">Yes</Button>
                    <Button type="button"onClick={onCloseModal} className="ml-3 w-20"  color="link">No</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}