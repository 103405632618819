import React from "react"
import styles from "./StreamingSettings.module.scss";
import { Button, Dropdown, FieldGroup, Form, Icon, Input, TBody, TD, TR, Table, TextArea, Switch, Card, Tab } from "@stockraken/ui";
import i18next from "i18next";
import { RoomService } from "../../Services/Room.service";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { RoomAction } from "../../Redux/Actions/Room.action";
import { IHTMLFormEvent, IHTMLInputEvent, IHTMLTextAreaEvent, IKeyValue } from "@stockraken/ui/types";
import { LiveStreamAction } from "../../Redux/Actions/LiveStream.action";

export function StreamingSettings() {

    const dispatch = useAppDispatch();
    const { rooms } = useAppSelector(state => state.RoomReducer);
    const { roomId, title, description, mutedViewers, streamingStarted, ...roomPrivacy } = useAppSelector(state => state.LiveStreamReducer);
    const [activeTabId, setActiveTabId] = React.useState<string>("streaming-tab-settings");

    const onClickTabLink = (target: string) => {
        setActiveTabId(target);
    }

    const updateForm = (name: string, value: string | boolean) => {
        let data: IKeyValue<string | boolean> = {};
        data[name] = value;
        dispatch({ "type": LiveStreamAction.SET_LIVE_STREAM_DETAILS, "value": data });
    }

    const onUpdateRoomId = (name: string, value: string) => updateForm(name, value);
    const onChange = (event: IHTMLInputEvent | IHTMLTextAreaEvent) => updateForm(event.target.name, event.target.value);
    const onChangeSwitch = (event: IHTMLInputEvent) => updateForm(event.target.name, event.target.checked);

    const onSubmit = (event: IHTMLFormEvent) => {
        event.preventDefault();
        const request = {
            "roomId": roomId,
            "title": title,
            "description": description,
            "private": roomPrivacy.private,
            "muteViewers": mutedViewers
        };
        dispatch({ "type": LiveStreamAction.SET_LIVE_STREAM_STARTED });
        console.log(request);
    }

    const GetRooms = React.useCallback(() => {
        if (rooms == null) {
            const roomService = new RoomService();
            roomService.GetRooms().then(response => {
                dispatch({ "type": RoomAction.SET_ROOMS, "value": response });
            });
        }
    }, [dispatch, rooms]);

    React.useEffect(() => {
        GetRooms();
    }, [GetRooms]);

    return (
        <div className={styles["streaming-details"]}>
            <Tab defaultActiveId={activeTabId} onClick={onClickTabLink} className="h-full">
                <Tab.Header>
                    <Tab.Link target="streaming-tab-settings" href="#">
                        <Icon
                            className="mr-2"
                            name="icon-loading"
                            width="16px"
                            height="16px"
                            color={activeTabId === "streaming-tab-settings" ? "#7c3aed" : "#333"} />
                        Settings
                    </Tab.Link>
                    <Tab.Link target="streaming-tab-chat" href="#">
                        <Icon
                            className="mr-2"
                            name="icon-bell"
                            width="16px"
                            height="16px"
                            color={activeTabId === "streaming-tab-chat" ? "#7c3aed" : "#333"} />
                        Chat
                    </Tab.Link>
                    <Tab.Link target="streaming-tab-info" href="#">
                        <Icon
                            className="mr-2"
                            name="icon-bug"
                            width="16px"
                            height="16px"
                            color={activeTabId === "streaming-tab-info" ? "#7c3aed" : "#333"} />
                        Informations
                    </Tab.Link>
                </Tab.Header>
                <Tab.Body>
                    <Tab.Content id="streaming-tab-settings">
                        <Form onSubmit={onSubmit} className="h-full">
                            <Card className="h-full">
                                <Card.Body>
                                    <FieldGroup>
                                        <label>Streaming Room</label>
                                        <Dropdown
                                            required
                                            name="roomId"
                                            onUpdate={onUpdateRoomId}
                                            value={roomId}
                                            as="select"
                                            disabled={streamingStarted}
                                            placeholder={i18next.t("translate-choose-streaming-room")}>
                                            {
                                                rooms &&
                                                rooms.map((item, index) => (
                                                    <Dropdown.Item key={index} value={item.id} text={item.name}>{item.name}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    </FieldGroup>
                                    <FieldGroup>
                                        <label>Streaming Title</label>
                                        <Input
                                            required
                                            type="text"
                                            name="title"
                                            onChange={onChange}
                                            maxLength={50}
                                            disabled={streamingStarted}
                                            placeholder="Example : Learning advanced mathematics." />
                                    </FieldGroup>
                                    <FieldGroup>
                                        <label>Streaming Description</label>
                                        <TextArea
                                            required
                                            name="description"
                                            onChange={onChange}
                                            disabled={streamingStarted}
                                            placeholder="Example : In this course we will learn how to calculate the angle of a triangle.."
                                            maxLength={500}
                                            rows={3} />
                                    </FieldGroup>
                                    <FieldGroup>
                                        <Switch
                                            name="private"
                                            checked={roomPrivacy.private}
                                            disabled={streamingStarted}
                                            onChange={onChangeSwitch}>Private channel</Switch>
                                    </FieldGroup>
                                    <FieldGroup>
                                        <Switch
                                            name="mutedViewers"
                                            checked={mutedViewers}
                                            disabled={streamingStarted}
                                            onChange={onChangeSwitch}>Mute viewers</Switch>
                                    </FieldGroup>
                                </Card.Body>
                                <Card.Footer>
                                    <Button type="submit" disabled={streamingStarted}>Start Streaming</Button>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Tab.Content>
                    <Tab.Content id="streaming-tab-info">
                        <StreamingInformationTable />
                    </Tab.Content>
                </Tab.Body>
            </Tab>

        </div>
    )
}

const StreamingInformationTable = () => {
    return (
        <Table className={styles["streaming-information-table"]}>
            <TBody>
                <TR>
                    <TD>URL</TD>
                    <TD>-</TD>
                </TR>
                <TR>
                    <TD>Started at</TD>
                    <TD>-</TD>
                </TR>
                <TR>
                    <TD>Timelapse</TD>
                    <TD>-</TD>
                </TR>
                <TR>
                    <TD>Room</TD>
                    <TD>-</TD>
                </TR>
                <TR>
                    <TD>Muted</TD>
                    <TD>-</TD>
                </TR>
                <TR>
                    <TD>Private</TD>
                    <TD>-</TD>
                </TR>
                <TR>
                    <TD>Viewers</TD>
                    <TD>-</TD>
                </TR>
            </TBody>
        </Table>
    )
}