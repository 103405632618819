import React, { useState, useRef } from "react";
import { IHTMLButtonEvent, IFormData } from "@stockraken/ui/types";
import { Modal } from "@stockraken/ui";
import { FileUploadUiAction } from "../../Redux/Actions/FileUi.action";
import { FileUploader } from "../FileUploader/FileUploader.component";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { FileUploadService } from "../../Services/FileUpload.service";
import { FilePrivacyDropdown } from "../FilePrivacyDropdown/FilePrivacyDropdown.component";
import { IFileUploadResponse } from "../../Responses/IFileUpload.response";
import { IHttpResponseModel } from "../../../Common/Models/HttpResponse.model";
import { FileUploadAction } from "../../Redux/Actions/FileUpload.action";

export function FileUploadModal() {

    const dispatch = useAppDispatch();
    const [filePrivacyId, setFilePrivacyId] = useState("00");
    const { showFileUploadModal } = useAppSelector(state => state.fileUiReducer);
    const acceptedExtensions = useRef<Array<string>>([".docx"]);

    const uploadFile = React.useCallback((request: IFormData, func: (event: any, uuid: string) => any): Promise<IHttpResponseModel<IFileUploadResponse>> => {
        return new Promise((resolve, reject) => {
            const fileUploadService = new FileUploadService();
            return fileUploadService.uploadFiles(request, func).then(response => {
                dispatch({ type: FileUploadAction.UPDATE_FILES, value: response.data });
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    }, []);

    const onClose = React.useCallback((event: IHTMLButtonEvent) => {
        dispatch({ type: FileUploadUiAction.TOGGLE_FILE_UPLOAD_MODAL, value: false });
    }, [dispatch]);

    const updateFilePrivacy = React.useCallback((name: string, value: string) => {
        setFilePrivacyId(value);
    }, []);

    return (
        <Modal disposeOnClose={true} show={showFileUploadModal} onClose={onClose}>
            <Modal.Header className="!pb-0">
                <div className="w-1/3">
                    <FilePrivacyDropdown filePrivacyId={filePrivacyId} handleOnUpdate={updateFilePrivacy} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="w-full mt-3">
                    <FileUploader
                        acceptedExtensions={acceptedExtensions.current}
                        autoUpload={true}
                        onUploadFile={uploadFile}
                        filePrivacyId={filePrivacyId} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

