import React from "react";
import { Button, FieldGroup, Form, Icon, Input, Label, Modal } from "@stockraken/ui";
import { IHTMLFormEvent, IHTMLInputEvent } from "@stockraken/ui/types";
import { UserService } from "../../Services/User.service";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { UserUiAction } from "../../Redux/Actions/UserUi.action";

export function UserUpdatePasswordModal() {

    const dispatch = useAppDispatch();
    const { showUpdatePasswordModal } = useAppSelector(state => state.UserUiReducer);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = React.useState<string>("");
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [repeatedPassword, setRepeatedPassword] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const passwordMatch: boolean = newPassword === repeatedPassword;

    const onChangeCurrentPassowrd = (event: IHTMLInputEvent) => setCurrentPassword(event.target.value);
    const onChangeNewPassowrd = (event: IHTMLInputEvent) => setNewPassword(event.target.value);
    const onChangeRepeatedPassowrd = (event: IHTMLInputEvent) => setRepeatedPassword(event.target.value);

    const updatePassword = (event: IHTMLFormEvent) => {
        event.preventDefault();
        if (event.target.checkValidity()) {
            if (passwordMatch) {
                setErrorMessage(null);
                setIsLoading(true);
                UserService.updatePassword({ currentPassword: currentPassword, newPassword: newPassword }).then(response => {
                    setIsLoading(false);
                    //dispatch({ type: UserUiAction.TOGGLE_USER_UPDATE_PASSWORD_MODAL, value: false });
                }).catch(error => {
                    setIsLoading(false);
                    setErrorMessage(error.messge);
                });
            } else {
                setErrorMessage("New password and repeated password, does not match.");
            }
        }
    }

    const onCloseModal = () => {
        dispatch({ type: UserUiAction.TOGGLE_USER_UPDATE_PASSWORD_MODAL, value: false });
    }

    return (
        <Modal show={showUpdatePasswordModal} onClose={onCloseModal} size="sm">
            <Modal.Header><h6>Update Password</h6></Modal.Header>
            <Modal.Body>
                <Form onSubmit={updatePassword} message={errorMessage} messagePosition="top">
                    <FieldGroup>
                        <Label>Current Password</Label>
                        <Input type="password" onChange={onChangeCurrentPassowrd} value={currentPassword} minLength={6} maxLength={30} placeholder="Enter your current password.." />
                    </FieldGroup>
                    <FieldGroup>
                        <Label>New Password</Label>
                        <Input type="password" onChange={onChangeNewPassowrd} value={newPassword} minLength={6} maxLength={30} placeholder="Enter your new password.." />
                    </FieldGroup>
                    <FieldGroup>
                        <Label>Repeat Password</Label>
                        <Input type="password" onChange={onChangeRepeatedPassowrd} value={repeatedPassword} minLength={6} maxLength={30} placeholder="Repeat your new password.." />
                    </FieldGroup>
                    <div className="flex w-full">
                        <Button type="submit" disabled={isLoading} hasSpinner className="ml-auto">Save</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}