import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { route as identityRoute } from './Identity/Pages.route';
import { route as coreRoute } from './Core/Pages.route';
import { route as communicationRoute } from './Communication/Pages.route';
import { HomePage } from './Identity/Pages/HomePage/Home.page';
import { SignupPage } from './Identity/Pages/SiginupPage/Signup.page';
import { FileSignedPage } from './Core/Pages/FileSignedPage/FileSigned.page';
import { SigninPage } from './Identity/Pages/SigninPage/Signin.page';
import { FileUploadedPage } from './Core/Pages/FileUploadedPage/FileUploaded.page';
import { FileSignDownloadPage } from './Core/Pages/FileSignDownloadPage/FileSignDownload.page';
import { StreamingPage } from './Communication/Pages/StreamingPage/Streaming.component';

export function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={identityRoute.Signin}
          element={<SigninPage />} />
        <Route path={identityRoute.Signup}
          element={<SignupPage />} />
        <Route path={identityRoute.Home}
          element={<HomePage />} />
        <Route path={coreRoute.UploadedFiles}
          element={<FileUploadedPage />} />
        <Route path={coreRoute.SignedFiles}
          element={<FileSignedPage />} />
        <Route path={coreRoute.VerificationFile}
          element={<FileSignDownloadPage />} />
        <Route path={communicationRoute.Streaming}
          element={<StreamingPage />} />
      </Routes>
    </BrowserRouter>
  );
}
