import React from "react";
import { IHTMLButtonEvent } from "@stockraken/ui/types";
import { Button, Icon, Table, TBody, TD, TR } from "@stockraken/ui";
import { IFileSignDownloadProps } from "../../Props/FileSign.props";
import styles from "./FileSignDownloadCard.module.scss";
import { useFileSignService } from "../../Services/FileSign.service";
import UiConfig from "../../Configurations/Default.config.json";

export function FileSignDownloadCard({ fileSigned, httpStatus }: IFileSignDownloadProps) {

    const { downloadFile } = useFileSignService();

    const onClickHandler = (event: IHTMLButtonEvent) => {
        let id = event.currentTarget.value;
        downloadFile(id);
    }

    return (
        <div className={styles["file-signed-download-card"]}>
            {
                fileSigned && httpStatus === 200 &&
                <React.Fragment>
                    <div className="flex items-center mb-5">
                        <Icon className="float-left mr-2" name="icon-checked-badge" width="23px" height="23px" color="#06D6B6" />
                        <h6>File Found !</h6>
                    </div>
                    <p className="mb-3">We have find the document you are looking for! Here's some information to validate this document.</p>
                    <Table theme="transparent">
                        <TBody>
                            <TR>
                                <TD>File name :</TD>
                                <TD>{fileSigned.fileProperties.originalFileName}</TD>
                            </TR>
                            <TR>
                                <TD>Length :</TD>
                                <TD>{fileSigned.fileProperties.length}</TD>
                            </TR>
                            <TR>
                                <TD>Sign Time :</TD>
                                <TD>{fileSigned.fileProperties.creationTime}</TD>
                            </TR>
                            <TR>
                                <TD>File extension :</TD>
                                <TD>{fileSigned.fileProperties.extension}</TD>
                            </TR>
                            <TR>
                                <TD>File privacy :</TD>
                                <TD>{fileSigned.filePrivacy.label}</TD>
                            </TR>
                            <TR>
                                <TD>Signed By :</TD>
                                <TD>{fileSigned.firstName} {fileSigned.lastName}</TD>
                            </TR>
                        </TBody>
                    </Table>
                    {
                        UiConfig.FileSignExternalDownloadAllowed &&
                        <Button className="mt-8" onClick={onClickHandler} value={fileSigned.id}>Download File</Button>
                    }
                </React.Fragment>
            }
            {
                httpStatus === 401 &&
                <React.Fragment>
                    <div className="flex items-center mb-5">
                        <Icon className="float-left mr-2" name="icon-warning" width="23px" height="23px" />
                        <h6>Unauthorized Access !</h6>
                    </div>
                    <p>To download this file you need to login first. If you don't have any access to our plateform please contact the administration team.</p>
                </React.Fragment>
            }
        </div>
    )
}