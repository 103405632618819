import React from "react";
import { IKeyValue } from "@stockraken/ui/types";
import { Dropdown, Input, FieldGroup } from "@stockraken/ui";
import { useGenders } from "../../../Core/Services/Gender.service";
import { useAppDispatch } from "../../../Redux/Hooks/Hooks";
import { ISignupPersonalFormProps } from "../../Props/Signup.props";
import { SignupAction } from "../../Redux/Actions/Signup.action";

export function SignupPersonalForm({ firstName, lastName, birthday, genderId }: ISignupPersonalFormProps) {

    const dispatch = useAppDispatch();
    const { genders } = useGenders();

    const handleOnUpdate = (name: string, value: string) => {
        let json: IKeyValue<string> = {};
        json[name] = value;
        dispatch({ type: SignupAction.UPDATE_REQUEST_USER_INFORMATION, value: json });
    }

    return (
        <React.Fragment>
            <FieldGroup>
                <Input
                    type="text"
                    placeholder={"First Name"}
                    name="firstName"
                    requiredErrorMessage="Please Fill your First Name."
                    pattern="([a-zA-Z]{3,30}\s*)+"
                    title="The First Name should contain only letters."
                    autoFocus={true}
                    required={true}
                    minLength={3}
                    maxLength={50}
                    onUpdate={handleOnUpdate}
                    value={firstName}
                />
            </FieldGroup>
            <FieldGroup>
                <Input
                    type={"text"}
                    placeholder={"Last Name"}
                    name={"lastName"}
                    autoFocus={true}
                    required={true}
                    minLength={2}
                    maxLength={50}
                    onUpdate={handleOnUpdate}
                    value={lastName} />
            </FieldGroup>
            <FieldGroup>
                <Input
                    type="date"
                    placeholder="Birthday"
                    name={"birthday"}
                    autoFocus={true}
                    required={true}
                    minLength={8}
                    maxLength={10}
                    onUpdate={handleOnUpdate}
                    value={birthday} />
            </FieldGroup>
            <FieldGroup>
                <Dropdown
                    required={true}
                    placeholder="What's your Gender"
                    name="genderId"
                    onUpdate={handleOnUpdate}
                    value={genderId}>
                    {
                        genders &&
                        genders.map((item, index) => (
                            <Dropdown.Item key={index} value={item.id} text={item.label}>{item.label}</Dropdown.Item>
                        ))
                    }
                </Dropdown>
            </FieldGroup>
        </React.Fragment>
    )
}