import axios from "axios";
import { useEffect } from "react";
import { axiosErrorHandler } from "../../Common/Handlers/axios.handler";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks/Hooks";
import { GenderAction } from "../Redux/Actions/Gender.action";
import { IGenderResponse } from "../Responses/IGender.response";
import { api } from "../Services.api";

const http = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_CORE
});


class GenderService {
    public static getAllGenders() {
        return new Promise<Array<IGenderResponse>>((resolve, reject) => {
            http.get<Array<IGenderResponse>>(api.core.GetGenders).then(result => {
                resolve(result.data);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }
}

export function useGenders() {

    const dispatch = useAppDispatch();
    const { genders } = useAppSelector(state => state.genderReducer);

    useEffect(() => {
        if (genders === null) {
            GenderService.getAllGenders().then(result => {
                dispatch({ type: GenderAction.UPDATE_GENDERS, value: result });
            });
        }
    }, [genders]);

    return { genders } as const;

}