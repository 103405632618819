import React from "react";
import _ from "lodash";
import { IHTMLInputEvent } from "@stockraken/ui/types";
import { IRadioButtonGroupProps } from "@stockraken/ui/props";
import { RadioButtonGroup } from "@stockraken/ui";
import { useAppSelector } from "../../../Redux/Hooks/Hooks";
import { useFileSignService } from "../../Services/FileSign.service";
import { IFilePrivacyRequest } from "../../Requests/IFilePrivacy.request";
import { IFileUploadResponse } from "../../Responses/IFileUpload.response";

export function FilePrivacy() {

    const { updateFilePrivacy } = useFileSignService();
    const { filesSigned, selectedFiles } = useAppSelector(state => state.fileSignReducer);
    const [cuurentFile, setCurrentFile] = React.useState<IFileUploadResponse | null>(null);

    const radios = React.useRef<IRadioButtonGroupProps>({
        name: "privacy",
        data: [
            {
                value: "00",
                title: "Private",
                text: "Only logged users, can see that file.",
                icon: "icon-fingerprint"
            },
            {
                value: "01",
                title: "Public",
                text: "Everyone can see that file.",
                icon: "icon-fingerprint"
            }
        ]
    });

    const onChangeHandler = (event: IHTMLInputEvent) => {
        if (selectedFiles.length === 1) {
            let filePrivacyRequest: IFilePrivacyRequest = _.cloneDeep({
                filePrivacyId: event.target.value
            });
            updateFilePrivacy(selectedFiles[0], filePrivacyRequest);
        }
    };

    React.useEffect(() => {
        if (selectedFiles.length === 1 && filesSigned) {
            setCurrentFile(filesSigned.find(item => item.id === selectedFiles[0]) || null);
 
        } else {
            setCurrentFile(null);
        }
    }, [filesSigned, selectedFiles])

    return (
        cuurentFile &&
        <RadioButtonGroup
            name={radios.current.name}
            data={radios.current.data}
            currentValue={cuurentFile?.filePrivacy.id || "00"}
            onChange={onChangeHandler} />
    )
}