import React from "react";
import { IReactNodeProps } from "@stockraken/ui/props";
import styles from "./ContainerLayout.module.scss";
import { FilePrivacyModal } from "../../../Core/Components/FilePrivacyModal/FilePrivacyModal.component";
import { FileUploadModal } from "../../../Core/Components/FileUploadModal/FileUploadModal.component";
import { FileSignRemoveModal } from "../../../Core/Components/FileSignRemoveModal/FileSignRemoveModal.component";
import { FileUploadRemoveModal } from "../../../Core/Components/FileUploadRemoveModal/FileUploadRemoveModal.component";
import { ReportBugModal } from "../../Components/ReportBugModal/ReportBugModal.component";
import { LeftMenu } from "../../Components/LeftMenu/LeftMenu.component";
import FileSignModal from "../../../Core/Components/FileSignModal/FileSignModal.component";
import { UserUpdatePasswordModal } from "../../../Identity/Components/UserUpdatePasswordModal/UserUpdatePasswordModal.component";

export function ContainerLayout({ children }: IReactNodeProps) {

    return (
        <div className={styles["container-layout"]}>
            <div className="flex h-full max-h-full">
                <LeftMenu />
                <div className="relative flex-auto p-4">
                    {children}
                </div>
            </div>
            <div className="modal-container">
                <FileUploadModal />
                <FilePrivacyModal />
                <FileSignRemoveModal />
                <FileUploadRemoveModal />
                <FileSignModal />
                <ReportBugModal />
                <UserUpdatePasswordModal />
            </div>
        </div>
    )
}