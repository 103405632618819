import React from "react";
import styles from "./SignupForm.module.scss";
import { SignupPersonalForm } from "../SignupPersonalForm/SignupPersonalForm";
import { SignupAdressForm } from "../SignupAdressForm/SignupAdressForm";
import { SignupAccountForm } from "../SignupAccountForm/SignupAccountForm";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { IHTMLButtonEvent, IHTMLFormEvent } from "../../../Common/Types/HTMLEvent";
import { SignupAction } from "../../Redux/Actions/Signup.action";
import { UserService } from "../../Services/User.service";
import { Form, Progress, Button } from "@stockraken/ui";
import { SignupSuccess } from "../SignupSuccess/SignupSuccess.component";
import { IProgressItemProps } from "@stockraken/ui/props";

export function SignupForm() {

    const dispatch = useAppDispatch();
    const [step, setStep] = React.useState<number>(1);
    const { request, response } = useAppSelector(state => state.signupReducer);

    const steps = React.useRef<Array<IProgressItemProps>>([
        { step: 1, icon: "icon-user", text: "Personal Information" },
        { step: 2, icon: "icon-home", text: "Adress Information" },
        { step: 3, icon: "icon-fingerprint", text: "Account Information" }
    ]);

    const handleOnNext = () => {
        if (step !== 4) {
            setStep(step + 1)
        }
    };

    const handleOnPrevious = (event: IHTMLButtonEvent) => {
        if (step !== 1) {
            setStep(step - 1)
        }
    };

    const handleOnStep = (goToStep: number) => {
        if (goToStep < step) {
            setStep(goToStep);
        }
    }

    const handleOnSubmit = (event: IHTMLFormEvent) => {
        event.preventDefault();
        if (step === 3) {
            UserService.register(request).then(result => {
                dispatch({ type: SignupAction.UPDATE_RESPONSE, value: result })
                handleOnNext();
            });
        } else {
            handleOnNext();
        }
    }

    return (
        <Form onSubmit={handleOnSubmit} className={`${styles["signup-form-app"]} ${styles["smoked-theme-app"]}`}>
            {
                step !== 4 &&
                <Progress data={steps.current} currentStep={step} onStep={handleOnStep} />
            }
            {
                step === 1 &&
                <SignupPersonalForm
                    firstName={request.userInformation.firstName}
                    lastName={request.userInformation.lastName}
                    birthday={request.userInformation.birthday}
                    genderId={request.userInformation.genderId}
                />
            }
            {
                step === 2 &&
                <SignupAdressForm
                    street={request.userInformation.address.street}
                    postalCode={request.userInformation.address.postalCode}
                    unity={request.userInformation.address.unity}
                    countryIso={request.userInformation.address.countryIso}
                    stateAbbreviation={request.userInformation.address.stateAbbreviation}
                />
            }
            {
                step === 3 &&
                <SignupAccountForm
                    username={request.username}
                    email={request.email}
                    phone={request.phone}
                    password={request.password}
                    repeatedPassword={request.repeatedPassword}
                />
            }
            {
                step === 4 &&
                <SignupSuccess message={response.message} />
            }
            {
                step !== 4 &&
                <div className={`grid ${step > 1 ? "grid-cols-2 gap-2" : ""}`}>
                    {
                        step > 1 &&
                        <Button type="submit" onClick={handleOnPrevious} className="mt-8">
                            Previous
                        </Button>
                    }
                    <Button type="submit" className="mt-8">
                        {
                            step === 3 ? "Register" : "Next"
                        }
                    </Button>
                </div>
            }
        </Form>
    )
}