import React from "react";
import { FileUploadedLayout } from "../../Layouts/FileUploadedLayout/FileUploadedLayout.layout";
import { AuthenticationRouterLayout } from "../../../Identity/Layouts/AuthenticationRouterLayout/AuthenticationRouter.layout";

export function FileUploadedPage() {
    return (
        <AuthenticationRouterLayout.Required>
            <FileUploadedLayout />
        </AuthenticationRouterLayout.Required>
    )
}