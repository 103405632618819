import { IUserUiState } from "../../States/UserUi.state";
import { UserUiAction } from "../Actions/UserUi.action";

const initialState: IUserUiState = {
    showUpdatePasswordModal: false
}

export function UserUiReducer(state = initialState, action: any ){
    let nextState: IUserUiState;
    switch (action.type) {
        case UserUiAction.TOGGLE_USER_UPDATE_PASSWORD_MODAL:
            nextState = {
                ...state,
                showUpdatePasswordModal: action.value || !state.showUpdatePasswordModal
            }
            return nextState || state;
        default:
            return state;
    }
}