import React from "react";
import { FileSignLayout } from "../../Layouts/FileSignLayout/FileSignLayout.layout";
import { AuthenticationRouterLayout } from "../../../Identity/Layouts/AuthenticationRouterLayout/AuthenticationRouter.layout";

export function FileSignedPage() {

    return (
        <AuthenticationRouterLayout.Required>
            <FileSignLayout />
        </AuthenticationRouterLayout.Required>
    )
}