import { FileSignAction } from "../Actions/FileSign.action";
import { IFileSignState } from "../../States/IFileSign.state";
import { IFileSignResponse } from "../../Responses/IFileSign.response";
import _ from "lodash";

const initialState: IFileSignState = {
    filesSigned: null,
    selectedFiles: new Array<string>(),
    fileSigned: null,
    httpStatus: null,
    currentFileId: null,
    currentFilePrivacyId: null,
    maxItems: 0,
    currentPage: 0,
    maxPages: 0,
    skip: 0,
    take: 25,
    nextDisabled: true,
    previousDisabled: true,
};

export function fileSignReducer(state = initialState, action: any) {

    let nextState: IFileSignState;

    switch (action.type) {
        case FileSignAction.SET_CURRENT_FILE_ID:
            nextState = {
                ...state,
                currentFileId: action.value,
                fileSigned: state.filesSigned?.find(item => item.id === action.value) || null
            }
            return nextState || state;
        case FileSignAction.SET_CURRENT_FILE_PRVIACY_ID:
            nextState = {
                ...state,
                currentFilePrivacyId: action.value
            }
            return nextState || state;
        case FileSignAction.SET_DOWNLOAD_FILE:
            nextState = {
                ...state,
                fileSigned: action.value.fileSigned,
                httpStatus: action.value.httpStatus
            }
            return nextState || state;
        case FileSignAction.SET_SELECTED_FILES:
            nextState = {
                ...state,
                selectedFiles: action.value
            }
            return nextState || state;
        case FileSignAction.SET_FILES:
            nextState = {
                ...state,
                filesSigned: action.value.data,
                maxItems: action.value.maxItems,
                currentPage: action.value.currentPage,
                maxPages: action.value.maxPages,
                skip: action.value.skip,
                take: action.value.take,
                nextDisabled: action.value.nextDisabled,
                previousDisabled: action.value.previousDisabled,
            }
            return nextState || state;
        case FileSignAction.REMOVE_FILES:
            nextState = {
                ...state,
                filesSigned: state.filesSigned!.filter(item => !action.value.includes(item.id)) || null,
                selectedFiles: state.selectedFiles.filter(id => !action.value.includes(id)) || []
            }
            return nextState || state;
        case FileSignAction.UPDATE_FILES:
            nextState = {
                ...state,
                filesSigned: [...action.value, ...(state.filesSigned || [])]
            }
            return nextState || state;
        case FileSignAction.UPDATE_FILE_PRIVACY:
            nextState = {
                ...state,
                filesSigned: updateFilesSigned(state, action),
                fileSigned: updateFileSigned(state, action)
            }
            return nextState || state;
        default:
            return state;
    }
}

const updateFilesSigned = (state: IFileSignState, action: any): IFileSignResponse[] | null => {
    if (state.filesSigned) {
        let nextFileSigned = _.cloneDeep(state.filesSigned);
        nextFileSigned.map(item => {
            if (item.id === action.value.id) {
                item.filePrivacy = {
                    id: action.value.filePrivacyId,
                    label: action.value.filePrivacyId === "00" ? "Private" : "Public",
                }
            }
            return item;
        });
        return nextFileSigned;
    } else {
        return null;
    }
}

const updateFileSigned = (state: IFileSignState, action: any): IFileSignResponse | null => {
    if (state.fileSigned?.filePrivacy) {
        let nextFileSigned = _.cloneDeep(state.fileSigned);
        nextFileSigned.filePrivacy = {
            id: action.value.filePrivacyId,
            label: action.value.filePrivacyId === "00" ? "Private" : "Public",
        }
        return nextFileSigned;
    } else {
        return null;
    }
}