import { IUserInformation } from "../../Responses/UserInformation.response";
import { UserAction } from "../Actions/User.action";

const initialState: IUserInformation | null = null;

export function UserReducer(state = initialState, action: any ){
    let nextState: IUserInformation;

    switch (action.type) {
        case UserAction.UPDATE_DATA:
            nextState = {
                ...state,
                ...action.value
            }
            return nextState || state;
        default:
            return state;
    }
}