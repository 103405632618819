import React from "react";
import { IHTMLButtonEvent, ITableGeneratorBody, ITableGeneratorHeader } from "@stockraken/ui/types";
import { Table } from "@stockraken/ui";
import { useFileSignService } from "../../Services/FileSign.service";
import { FileTableWrapper } from "../FileTableWrapper/FileTableWrapper.component";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { FileNameWrapper } from "../FileNameWrapper/FileNameWrapper.component";
import { Scroller } from "@stockraken/ui";
import { FileSignAction } from "../../Redux/Actions/FileSign.action";
import { IFileSignResponse } from "../../Responses/IFileSign.response";

export function FileSignTable() {

    const header = React.useRef<Array<ITableGeneratorHeader>>([
        { text: "File Name", filter: true, sort: true },
        { text: "Sign Time", filter: true, sort: true },
        { text: "Upload By", filter: true, sort: true },
        { text: "Size", filter: true, sort: true },
        { text: "Privacy", filter: true, sort: true }
    ]);

    const dispatch = useAppDispatch();
    const { filesSigned, currentPage, maxPages, nextDisabled, previousDisabled } = useAppSelector(state => state.fileSignReducer);
    const { search } = useAppSelector(state => state.fileUiReducer);
    const { getFiles } = useFileSignService(true);

    React.useEffect(() => {
        getFiles(currentPage);
    }, []);

    const getNext = (event: IHTMLButtonEvent) => {
        getFiles(currentPage + 1);
    }

    const getPrevious = (event: IHTMLButtonEvent) => {
        getFiles(currentPage - 1);
    }

    const onSelect = (data: Array<string | number>) => {
        dispatch({ type: FileSignAction.SET_SELECTED_FILES, value: [...data] });
    }

    const getTableGeneratorBody = (filesSigned: IFileSignResponse[] | null): ITableGeneratorBody[] => {
        let nextBody: Array<ITableGeneratorBody> = [];

        if (filesSigned) {
            nextBody = filesSigned.map(item => {
                return {
                    value: item.id,
                    data: [
                        () => <FileNameWrapper filename={item.fileProperties.originalFileName} extension={item.fileProperties.extension} />,
                        item.fileProperties.creationTime,
                        `${item.firstName} ${item.lastName}`,
                        item.fileProperties.length,
                        item.filePrivacy.label]
                }
            });
        }

        return nextBody;
    }

    const body = React.useMemo(() => getTableGeneratorBody(filesSigned), [filesSigned]);

    return (
        <FileTableWrapper
            getNext={getNext}
            getPrevious={getPrevious}
            nextDisabled={nextDisabled}
            previousDisabled={previousDisabled}
            maxPages={maxPages}
            currentPage={currentPage}>
            <Scroller>
                {
                    body &&
                    <Table.Generator
                        borderless
                        size="medium"
                        header={header.current}
                        body={body}
                        search={search}
                        selectable={true}
                        onSelect={onSelect} />
                }
            </Scroller>
        </FileTableWrapper>
    )
}