import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFileSignService } from "../../Services/FileSign.service";
import { FileSignDownloadCard } from "../../Components/FileSignDownloadCard/FileSignDownloadCard.component";

export function FileSignDownloadPage() {

    const params = useParams();
    const { fileSigned, httpStatus, getFileById } = useFileSignService();

    useEffect(() => {
        if (params.id) {
            getFileById(params.id);
        }
    }, [params]);

    return (
        <div className="h-full flex justify-center items-center">
            <FileSignDownloadCard httpStatus={httpStatus} fileSigned={fileSigned} />
        </div>
    )
}