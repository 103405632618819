import React from "react";
import { IKeyValue } from "@stockraken/ui/types";
import { FieldGroup, Input } from "@stockraken/ui";
import { useAppDispatch } from "../../../Redux/Hooks/Hooks";
import { ISignupAccountProps } from "../../Props/Signup.props";
import { SignupAction } from "../../Redux/Actions/Signup.action";

export function SignupAccountForm({ username, email, phone, password, repeatedPassword }: ISignupAccountProps) {

    const dispatch = useAppDispatch();

    const handleOnUpdate = (name: string, value: string) => {
        let json: IKeyValue<string> = {};
        json[name] = value;
        dispatch({ type: SignupAction.UPDATE_REQUEST, value: json });
    }

    return (
        <React.Fragment>
            <FieldGroup>
                <Input
                    type="text"
                    placeholder="Username"
                    name="username"
                    autoFocus={true}
                    required={true}
                    minLength={3}
                    maxLength={25}
                    onUpdate={handleOnUpdate}
                    value={username} />
            </FieldGroup>
            <FieldGroup>
                <Input
                    type="email"
                    placeholder="exemple@domain.com"
                    name="email"
                    required={true}
                    minLength={5}
                    maxLength={50}
                    onUpdate={handleOnUpdate}
                    value={email} />
            </FieldGroup>
            <FieldGroup>
                <Input
                    type="text"
                    placeholder="Phone Number"
                    name="phone"
                    required={true}
                    minLength={5}
                    maxLength={50}
                    onUpdate={handleOnUpdate}
                    value={phone} />
            </FieldGroup>
            <FieldGroup>
                <Input
                    type="password"
                    placeholder="Password"
                    name="password"
                    required={true}
                    minLength={6}
                    maxLength={50}
                    onUpdate={handleOnUpdate}
                    value={password} />
            </FieldGroup>
            <FieldGroup>
                <Input
                    type="password"
                    placeholder="Repeat Password"
                    name="repeatedPassword"
                    required={true}
                    minLength={6}
                    maxLength={50}
                    onUpdate={handleOnUpdate}
                    value={repeatedPassword} />
            </FieldGroup>
        </React.Fragment>
    )
}