import { FileUploadAction } from "../Actions/FileUpload.action";
import { IFileUploadState } from "../../States/IFileUpload.state";

const initialState: IFileUploadState = {
    filesUploaded: null,
    selectedFiles: new Array<string>(),
    currentFileId: null,
    maxItems: 0,
    currentPage: 0,
    maxPages: 0,
    skip: 0,
    take: 25,
    nextDisabled: true,
    previousDisabled: true,
};

export function fileUploadReducer(state = initialState, action: any) {
    let nextState: IFileUploadState;

    switch (action.type) {
        case FileUploadAction.SET_CURRENT_FILE_ID:
            nextState = {
                ...state,
                currentFileId: action.value
            }
            return nextState || state;
        case FileUploadAction.SET_FILES:
            nextState = {
                ...state,
                filesUploaded: action.value.data,
                maxItems: action.value.maxItems,
                currentPage: action.value.currentPage,
                maxPages: action.value.maxPages,
                skip: action.value.skip,
                take: action.value.take,
                nextDisabled: action.value.nextDisabled,
                previousDisabled: action.value.previousDisabled,
            }
            return nextState || state;
        case FileUploadAction.SET_SELECTED_FILES:
            nextState = {
                ...state,
                selectedFiles: action.value
            }
            return nextState || state;
        case FileUploadAction.REMOVE_FILES:
            nextState = {
                ...state,
                filesUploaded: state.filesUploaded?.filter(item => !action.value.includes(item.id)) || null,
                selectedFiles: state.selectedFiles.filter(id => !action.value.includes(id)) || []
            }
            return nextState || state;
        case FileUploadAction.UPDATE_FILES:
            nextState = {
                ...state,
                filesUploaded: [action.value, ...(state.filesUploaded || [])]
            }
            return nextState || state;
        default:
            return state;
    }
}