import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { axiosErrorHandler } from "../../Common/Handlers/axios.handler";
import { IPaginationModel } from "../../Common/Models/Pagination.model";
import { IFormData } from "../../Common/Types/FormData";
import { useAppDispatch } from "../../Redux/Hooks/Hooks";
import { FileUploadUiAction } from "../Redux/Actions/FileUi.action";
import { FileUploadAction } from "../Redux/Actions/FileUpload.action";
import { IFileUploadResponse } from "../Responses/IFileUpload.response";
import { api } from "../Services.api";
import { FileDownloaderService } from "./FileDownloader.service";
import { IHttpResponseModel } from "../../Common/Models/HttpResponse.model";

const http = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_CORE,
    headers: {
        "Authorization": `Bearer ${Cookies.get("stockraken-jwt")}`
    }
});

const httpFormData = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_CORE,
    headers: {
        "Authorization": `Bearer ${Cookies.get("stockraken-jwt")}`,
        'Content-Type': 'multipart/form-data'
    }
});

export class FileUploadService {

    public controller = new AbortController();

    public static getFiles(currentPage: number) {
        return new Promise<IPaginationModel<IFileUploadResponse>>((resolve, reject) => {
            http.get<IPaginationModel<IFileUploadResponse>>(`${api.core.GetUploadFiles}/${currentPage}`).then(result => {
                resolve(result.data);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public static downloadFile(id: string) {
        return new Promise<AxiosResponse<Blob>>((resolve, reject) => {
            http.get<Blob>(`${api.core.DownloadUploadFile}/${id}`, { responseType: "blob" }).then(result => {
                resolve(result);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public uploadFiles(request: IFormData, onUploadProgress: (event: any, uuid: string) => any): Promise<IHttpResponseModel<IFileUploadResponse>> {
        return new Promise((resolve, reject) => {
            httpFormData.post<IHttpResponseModel<IFileUploadResponse>>(api.core.UploadFile, request.formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                //signal: this.controller?.signal,
                onUploadProgress: ProgressEvent => onUploadProgress(ProgressEvent, request.uuid)
            }).then(result => {
                resolve(result.data);
            }).catch((error: any) => {
                axiosErrorHandler(error);
                reject(error);
            });
        })
    }

    public static removeFiles(ids: Array<string>) {
        return new Promise<AxiosResponse>((resolve, reject) => {
            http.post(`${api.core.RemoveUploadFiles}`, { "ids": ids }).then(result => {
                resolve(result);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public cancelUpload = () => {
        this.controller.abort();
    }
}

export function useFileUploadService() {

    const dispatch = useAppDispatch();

    const getFiles = (currentPage: number) => {
        FileUploadService.getFiles(currentPage).then(result => {
            dispatch({ type: FileUploadAction.SET_FILES, value: result });
        });
    }

    const removeFiles = (ids: Array<string>) => {
        return new Promise<void>((resolve, reject) => {
            FileUploadService.removeFiles(ids).then(() => {
                dispatch({ type: FileUploadAction.REMOVE_FILES, value: ids });
                dispatch({ type: FileUploadUiAction.TOGGLE_FILE_REMOVE_MODAL, value: false });
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    const downloadFile = (id: string): void => {
        FileUploadService.downloadFile(id).then((result) => {
            FileDownloaderService.setupDownload(result);
        });
    }

    return { getFiles, downloadFile, removeFiles } as const;
}