export enum FilePrivacyUiAction {
    TOGGLE_FILE_PRIVACY_MODAL = "FILE_PRIVACY_TOGGLE_MODAL",
}

export enum FileSignUiAction {
    TOGGLE_FILE_REMOVE_MODAL = "FILE_SIGN_TOGGLE_REMOVE_MODAL",
}

export enum FileUiAction {
    SET_SEARCH = "SET_SEARCH_FILE_UI"
}

export enum FileUploadUiAction {
    TOGGLE_FILE_REMOVE_MODAL = "FILE_UPLOAD_TOGGLE_REMOVE_MODAL",
    TOGGLE_FILE_UPLOAD_MODAL = "TOGGLE_FILE_UPLOAD_MODAL",
    TOGGLE_FILE_SIGN_MODAL = "TOGGLE_FILE_SIGN_MODAL",
    UPDATE_PROGRESS = "FILE_UPLOAD_UPDATE_PROGRESS"
}