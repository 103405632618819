import { GenderAction } from "../Actions/Gender.action";
import { IGenderState } from "../../States/IGender.state";

const initialState: IGenderState = {
    genders: null
};

export function genderReducer(state = initialState, action: any) {

    let nextState: IGenderState;

    switch (action.type) {
        case GenderAction.UPDATE_GENDERS:
            nextState = {
                ...state,
                genders: action.value
            }
            return nextState || state;
        default:
            return state;
    }
}