import { AppLayout } from "../../../Common/Layouts/AppLayout/AppLayout";
import { ContainerLayout } from "../../../Common/Layouts/ContainerLayout/ContainerLayout";
import { StreamingSettings } from "../../Components/StreamingSettings/StreamingSettings.component";
import { StreamingDisplay } from "../../Components/StreamingDisplay/StreamingDisplay.component";

export function StreamingLayout() {
    return (
        <AppLayout>
            <ContainerLayout>
                <div className="w-full h-full flex">
                    <div className="w-3/4">
                        <StreamingDisplay />
                    </div>
                    <div className="w-1/4">
                        <StreamingSettings />
                    </div>
                </div>
            </ContainerLayout>
        </AppLayout>
    )
}