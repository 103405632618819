import axios from "axios";
import Cookies from "js-cookie";
import { api } from "../Services.api";
import { axiosErrorHandler } from "@stockraken/ui/handlers";
import { IRoomResponse } from "../Responses/Room.response";

const http = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_STREAMING,
    headers: {
        "Authorization": `Bearer ${Cookies.get("stockraken-jwt")}`
    }
});

export class RoomService {
    public GetRooms(): Promise<IRoomResponse> {
        return new Promise((resolve, reject) => {
            http.get<IRoomResponse>(api.streaming.Room.Get).then(result => {
                resolve(result.data);
            }).catch((error: any) => {
                axiosErrorHandler(error);
                reject(error);
            });
        })
    }
}