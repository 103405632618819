import { ISignupState } from "../../States/Signup.state";
import { SignupAction } from "../Actions/Signup.action";

const initialState: ISignupState = {
    response: {
        message: "",
        statusCode: 0,
        data: null
    },
    request: {
        email: "",
        password: "",
        repeatedPassword: "",
        username: "",
        phone: "",
        userInformation: {
            firstName: "",
            lastName: "",
            birthday: "",
            genderId: "",
            address: {
                street: "",
                postalCode: "",
                unity: "",
                countryIso: "",
                stateAbbreviation: ""
            }
        }
    }
};

export function signupReducer(state = initialState, action: any) {

    let nextState: ISignupState;

    switch (action.type) {
        case SignupAction.UPDATE_RESPONSE:
            nextState = {
                ...state,
                response: action.data
            }
            return nextState || state;
        case SignupAction.UPDATE_REQUEST:
            nextState = {
                ...state,
                request: Object.assign(state.request, action.value)
            }
            return nextState || state;
        case SignupAction.UPDATE_REQUEST_USER_INFORMATION:
            nextState = {
                ...state,
                request: Object.assign(state.request.userInformation, action.value)
            }
            return nextState || state;
        case SignupAction.UPDATE_REQUEST_USER_INFORMATION_ADDRESS:
            nextState = {
                ...state,
                request: Object.assign(state.request.userInformation.address, action.value)
            }
            return nextState || state;
        default:
            return state;
    }
}