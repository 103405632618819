import React from "react";
import { Button, Icon, Modal } from "@stockraken/ui";
import i18next from "i18next";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { IHTMLButtonEvent } from "@stockraken/ui/types";
import { FileUploadUiAction } from "../../Redux/Actions/FileUi.action";
import { useFileSignService } from "../../Services/FileSign.service";

export default function FileSignModal() {

    const dispatch = useAppDispatch();
    const { showFileSignModal } = useAppSelector(state => state.fileUiReducer);
    const { selectedFiles } = useAppSelector(state => state.fileUploadReducer);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const fileSignService = useFileSignService();

    const onClose = (event: IHTMLButtonEvent) => dispatch({ "type": FileUploadUiAction.TOGGLE_FILE_SIGN_MODAL, "value": false });

    const onSigneFiles = (event: IHTMLButtonEvent) => {
        if (selectedFiles.length > 0) {
            setIsLoading(true);
            fileSignService.signFiles(selectedFiles).then(response => {
                dispatch({ "type": FileUploadUiAction.TOGGLE_FILE_SIGN_MODAL, "value": false });
                setIsLoading(false);
            });
        }
    }

    return (
        <Modal show={showFileSignModal} onClose={onClose}>
            <Modal.Header>
                <div className="flex">
                    <Icon name="icon-qr-code" width="18px" height="18px" className="mr-2" />
                    <h6>{i18next.t("translate-sign-file", { count: selectedFiles.length })}</h6>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>{i18next.t("translate-sign-file-question", { count: selectedFiles.length })}</p>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex">
                    <Button type="button" onClick={onSigneFiles} disabled={isLoading} hasSpinner className="w-auto ml-auto">
                        {i18next.t("translate-continue")}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}