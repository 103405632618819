import React, { useRef, useContext } from "react";
import { useAppDispatch } from "../../../Redux/Hooks/Hooks";
import { Link, useLocation } from "react-router-dom";
import { AppAction } from "../../../Redux/Actions/App.action";
import { UserDropdown } from "../../../Identity/Components/UserDropdown/UserDropdown.component";
import { Icon, Menubar, MenuTogglerContext } from "@stockraken/ui";
import { MenuItemType, MenuType } from "../../Types/Menu";

export function LeftMenu() {

    const dispatch = useAppDispatch();
    const location = useLocation();

    const toggleNotificationList = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        dispatch({ type: AppAction.TOGGLE_NOTIFICATION_LIST });
    }

    const showReportBugModal = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        dispatch({ "type": AppAction.TOGGLE_REPORT_BUG_MODAL, value: true });
    }

    const isActive = (link: string) => {
        return link === location.pathname;
    }

    const menu = useRef<Array<MenuType>>([
        {
            "id": "GENERAL",
            "data": [
                { "id": 0, "text": "Home", "link": "/", "icon": "icon-file" },
                { "id": 1, "text": "Notifications", "link": "/all-signed", "icon": "icon-bell", "onClick": toggleNotificationList },
            ]
        },
        {
            "id": "FILES",
            "label": "Files",
            "data": [
                { "id": 2, "text": "Signed Files", "link": "/signed-files", "icon": "icon-signed-file", },
                { "id": 3, "text": "Uploaded Files", "link": "/uploaded-files", "icon": "icon-upload" },
            ]
        },
        /*{
            "id": "COMMUNICATION",
            "label": "Communication",
            "data": [
                { "id": 5, "text": "Streaming", "link": "/streaming", "icon": "icon-play-solid", },
            ]
        },*/
        {
            "id": "REPORTING",
            "label": "Reporting",
            "data": [
                { "id": 6, "text": "Report Bug", "link": "/all-files", "icon": "icon-bug", "onClick": showReportBugModal },
            ]
        }
    ]);

    return (
        <Menubar>
            <Menubar.Container>
                <LeftMenuHeader />
                {
                    menu.current &&
                    menu.current.map((item, itemIndex) => (
                        <React.Fragment key={itemIndex}>
                            {
                                item.label &&
                                <Menubar.Diviser label={item.label} />
                            }
                            {
                                item.data &&
                                item.data.map((element, elementIndex) => (
                                    <React.Fragment key={elementIndex}>
                                        <Menubar.Item
                                            key={elementIndex}
                                            active={isActive(element.link || "#")}
                                        >
                                            <Link
                                                to={element.link || "#"}
                                                {...(element.onClick ? { "onClick": element.onClick } : undefined)}>
                                                <LeftMenuItem data={element} isActive={isActive} />
                                            </Link>
                                        </Menubar.Item>
                                    </React.Fragment>
                                ))
                            }
                        </React.Fragment>
                    ))
                }
            </Menubar.Container>
            <UserDropdown />
        </Menubar>
    )
}

const LeftMenuItem: React.FC<{ data: MenuItemType, isActive: (link: string) => boolean }> = ({ data, isActive }) => {
    const showMenu = useContext<boolean>(MenuTogglerContext);
    return (
        <React.Fragment>
            {
                data.icon &&
                <Icon name={data.icon} width="20px" height="20px" color={isActive(data.link || "#") ? "#7c3aed" : "#333"} />
            }
            {
                showMenu && <h6>{data.text}</h6>
            }
        </React.Fragment>
    )
}

const LeftMenuHeader = () => {
    const showMenu = useContext<boolean>(MenuTogglerContext);
    return (
        <label className={`block mb-3 ${showMenu ? "visible" : "invisible"}`}>Menu</label>
    )
}