import { CountryAction } from "../Actions/Country.action";
import { ICountryState } from "../../States/ICountry.state";

const initialState: ICountryState = {
    countries: null,
    countriesStates: null
};

export function countryReducer(state = initialState, action: any) {

    let nextState: ICountryState;

    switch (action.type) {
        case CountryAction.UPDATE_COUNTRIES:
            nextState = {
                ...state,
                countries: action.value
            }
            return nextState || state;
        case CountryAction.UPDATE_STATES:
            nextState = {
                ...state,
                countriesStates: [...(state.countriesStates || []), action.value]
            }
            return nextState || state;
        default:
            return state;
    }
}