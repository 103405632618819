import React from "react";
import { Button } from "@stockraken/ui";
import styles from "./SignupSuccess.module.scss";

export function SignupSuccess({ message }: any) {
    return (
        <div className={styles["signup-success"]}>
            <h3>Signup successfuly!</h3>
            <p>{message}</p>
            <Button.Link to="/">Back to home</Button.Link>
        </div>
    )
}