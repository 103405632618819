import React from "react";
import { useFileUploadService } from "../../Services/FileUpload.service";
import { FileTableWrapper } from "../FileTableWrapper/FileTableWrapper.component";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { IHTMLButtonEvent, ITableGeneratorBody, ITableGeneratorHeader } from "@stockraken/ui/types";
import { Table } from "@stockraken/ui";
import { FileNameWrapper } from "../FileNameWrapper/FileNameWrapper.component";
import { FileUploadAction } from "../../Redux/Actions/FileUpload.action";
import { Scroller } from "@stockraken/ui";
import { IFileUploadResponse } from "../../Responses/IFileUpload.response";

export function FileUploadTable() {

    const header = React.useRef<Array<ITableGeneratorHeader>>([
        { text: "File Name", filter: true, sort: true },
        { text: "Upload Time", filter: true, sort: true },
        { text: "Upload By", filter: true, sort: true },
        { text: "Size", filter: true, sort: true },
        { text: "Privacy", filter: true, sort: true }
    ]);

    const dispatch = useAppDispatch();
    const { getFiles } = useFileUploadService();
    const { filesUploaded, currentPage, maxPages, nextDisabled, previousDisabled } = useAppSelector(state => state.fileUploadReducer);
    const { search } = useAppSelector(state => state.fileUiReducer);

    React.useEffect(() => {
        getFiles(currentPage);
    }, []);

    const getNext = (event: IHTMLButtonEvent) => {
        getFiles(currentPage + 1);
    }

    const getPrevious = (event: IHTMLButtonEvent) => {
        getFiles(currentPage - 1);
    }

    const onSelect = (data: Array<string | number>) => {
        dispatch({ type: FileUploadAction.SET_SELECTED_FILES, value: [...data] });
    }

    const getTableGeneratorBody = (filesUploaded: IFileUploadResponse[] | null): ITableGeneratorBody[] => {
        let nextBody: Array<ITableGeneratorBody> = [];

        if (filesUploaded) {
            nextBody = filesUploaded.map(item => {
                return {
                    value: item.id,
                    data: [
                        () => <FileNameWrapper filename={item.fileProperties.originalFileName} extension={item.fileProperties.extension} />,
                        item.fileProperties.creationTime,
                        `${item.firstName} ${item.lastName}`,
                        item.fileProperties.length,
                        item.filePrivacy.label]
                }
            });
        }

        return nextBody;
    }

    const body = React.useMemo(() => getTableGeneratorBody(filesUploaded), [filesUploaded]);

    return (
        <FileTableWrapper
            getNext={getNext}
            getPrevious={getPrevious}
            nextDisabled={nextDisabled}
            previousDisabled={previousDisabled}
            maxPages={maxPages}
            currentPage={currentPage}>
            <Scroller>
                {
                    body &&
                    <Table.Generator
                        borderless
                        size="medium"
                        header={header.current}
                        body={body}
                        selectable={true}
                        onSelect={onSelect}
                        search={search} />
                }
            </Scroller>
        </FileTableWrapper>
    )
}