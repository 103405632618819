import React from "react";
import { IHTMLButtonEvent } from "@stockraken/ui/types";
import { Button, Modal } from "@stockraken/ui"
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { FileSignUiAction } from "../../Redux/Actions/FileUi.action";
import { useFileSignService } from "../../Services/FileSign.service";
import styles from "./FileSignRemoveModal.module.scss";
import i18next from "i18next";

export function FileSignRemoveModal() {

    const dispatch = useAppDispatch();
    const { showFileSignRemoveModal } = useAppSelector(state => state.fileUiReducer);
    const { selectedFiles } = useAppSelector(state => state.fileSignReducer);
    const { removeFiles } = useFileSignService();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const onHideModal = (event: IHTMLButtonEvent) => {
        dispatch({ type: FileSignUiAction.TOGGLE_FILE_REMOVE_MODAL, value: false });
    }

    const onRemoveFiles = (event: IHTMLButtonEvent) => {
        if (selectedFiles.length > 0) {
            setIsLoading(true);
            removeFiles(selectedFiles).then(() => {
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            });
        }
    }

    return (
        <Modal size="sm" className={styles["file-sign-remove-modal"]} disposeOnClose={false} show={showFileSignRemoveModal} onClose={onHideModal}>
            <Modal.Header>
                <h6>{i18next.t("translate-delete-file", { count: selectedFiles.length })}</h6>
            </Modal.Header>
            <Modal.Body>
                <p>{i18next.t("translate-delete-file-question", { count: selectedFiles.length })}</p>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex">
                    <Button type="button" onClick={onRemoveFiles}  disabled={isLoading} hasSpinner className="ml-auto w-20">Yes</Button>
                    <Button type="button" onClick={onHideModal} className="ml-3 w-20" color="link">No</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}