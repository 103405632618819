import { RoomAction } from "../Actions/Room.action";
import { IRoomState } from "../States/Room.state";

const initialState: IRoomState = {
    rooms: null
};

export function RoomReducer(state = initialState, action: any) {

    let nextState: IRoomState;

    switch (action.type) {
        case RoomAction.SET_ROOMS:
            nextState = {
                ...state,
                rooms: action.value
            }
            return nextState || state;
        default:
            return state;
    }
}