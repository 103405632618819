import React from "react";
import { IReactNodeProps } from "@stockraken/ui/props";
import { DOM } from "@stockraken/ui";
import { TopMenu } from "../../Components/TopMenu/TopMenu.component";
import { UploadProgress } from "../../../Core/Components/FileUploadProgress/FileUploadProgress.component";
import { NotificationList } from "../../Components/NotificationList/NotificationList.component";

export function AppLayout({ children }: IReactNodeProps) {

    return (
        <DOM>
            <DOM.Header>
                <UploadProgress />
                <TopMenu />
            </DOM.Header>
            <DOM.Body>
                <NotificationList />
                {children}
            </DOM.Body>
        </DOM>
    )
}