import React from "react";
import styles from "./FileNameWrapper.module.scss";
import { iconMappingType } from "@stockraken/ui/types";
import { Icon } from "@stockraken/ui";

export const FileNameWrapper: React.FC<{ filename: string, extension: string }> = React.memo(({ filename, extension }) => {

    const [iconName, setIconName] = React.useState<iconMappingType>("icon-file");

    const getIconNameFromExtension = React.useCallback((): iconMappingType => {
        switch (extension.replace(".", "").toLowerCase()) {
            case "docx":
                return "icon-ms-word";
            case "doc":
                return "icon-ms-word";
            case "pdf":
                return "icon-pdf";
            default:
                return "icon-file";
        }
    }, [extension]);

    React.useEffect(() => {
        if (extension) {
            setIconName(getIconNameFromExtension());
        }
    }, [extension, setIconName, getIconNameFromExtension])

    return (
        <div className={styles["file-name-table"]}>
            <Icon name={iconName} width="20px" height="20px" />
            <span>{filename}</span>
        </div>
    )
});