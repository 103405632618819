import i18next from "i18next";
import EnResources from "./Assets/en-US.json";
import FrResources from "./Assets/fr-FR.json";
import ArResources from "./Assets/ar-DZ.json";

export function i18nextInitialization() {

    const langue = getCurrentLangue();

    return i18next.init({
        lng: langue,
        debug: false,
        resources: {
            en: {
                translation: EnResources
            },
            fr: {
                translation: FrResources
            },
            ar: {
                translation: ArResources
            }
        }
    });
}

function getCurrentLangue(): string {
    return sessionStorage.getItem("currentLangue") || "en";
}
