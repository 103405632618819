import React from "react";
import { AuthenticationValidator } from "../../Validators/Authentication.validator";
import { Navigate } from "react-router-dom";
import { route as identityRoute } from "../../Pages.route";
import { IReactNodeProps } from "@stockraken/ui/props";

function Required({ children }: IReactNodeProps) {

    if ((AuthenticationValidator.isAuthenticated())) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <Navigate to={identityRoute.Signin} />
            </React.Fragment>
        )
    }
}

function Auth({ children }: IReactNodeProps) {

    if ((!AuthenticationValidator.isAuthenticated())) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <Navigate to={identityRoute.Home} />
            </React.Fragment>
        )
    }
}

const Namespaces = Object.assign({ Required: Required, Auth: Auth });

export { Namespaces as AuthenticationRouterLayout }