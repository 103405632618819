import axios from "axios";
import Cookies from "js-cookie";
import { api } from "../Services.api";
import { axiosErrorHandler } from "../Handlers/axios.handler";
import { IFormData } from "@stockraken/ui/types";
import { IHttpResponseModel } from "../Models/HttpResponse.model";
import { IFileUploadResponse } from "../../Core/Responses/IFileUpload.response";
import { IReportBug } from "../../Core/Responses/IReportBug.response";

const http = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_CORE,
    headers: {
        "Authorization": `Bearer ${Cookies.get("stockraken-jwt")}`
    }
});

const httpFormData = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_CORE,
    headers: {
        "Authorization": `Bearer ${Cookies.get("stockraken-jwt")}`,
        'Content-Type': 'multipart/form-data'
    }
});

export class BugService {
    public InsertBug(request: IFormData, onUploadProgress: (event: any, uuid: string) => any): Promise<IHttpResponseModel<IReportBug>> {
        return new Promise((resolve, reject) => {
            httpFormData.post<IHttpResponseModel<IReportBug>>(api.common.Bug.Post, request.formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                //signal: this.controller?.signal,
                onUploadProgress: ProgressEvent => onUploadProgress(ProgressEvent, request.uuid)
            }).then(result => {
                resolve(result.data);
            }).catch((error: any) => {
                axiosErrorHandler(error);
                reject(error);
            });
        })
    }
}