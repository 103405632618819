import { AxiosResponse } from "axios";

export class FileDownloaderService {

    public static getFilenameFromContentDisposition(response: AxiosResponse<Blob>): string | undefined {
        const contentDisposition = response.headers["content-disposition"];
        const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
        const filename = match?.at(1);

        return filename;
    }

    public static getBlobLinkFromFileStreamResult(data: Blob, filename: string | undefined): HTMLAnchorElement {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");

        link.href = href;

        if (filename) {
            link.setAttribute("download", filename);
        }

        link.setAttribute("target", "_blank");

        return link;
    }

    public static setupDownload(response: AxiosResponse<Blob>) {
        const filename = this.getFilenameFromContentDisposition(response);
        const link = this.getBlobLinkFromFileStreamResult(response.data, filename);      
        link.click();
        URL.revokeObjectURL(link.href);
    }
}