import React from "react";
import { IHTMLButtonEvent } from "../../../Common/Types/HTMLEvent";
import { Button } from "@stockraken/ui";
import { useAppDispatch } from "../../../Redux/Hooks/Hooks";
import { ISigninLockedProps } from "../../Props/Signin.props";
import { SigninAction } from "../../Redux/Actions/Signin.action";
import styles from "./SigninLocked.module.scss";

export function SigninLocked({ message, reason, startTime, endTime }: ISigninLockedProps) {

    const dispatch = useAppDispatch();

    const handleOnClick = (event: IHTMLButtonEvent) => {
        resetStoreResponse();
    }

    const resetStoreResponse = () => {
        dispatch({
            "type": SigninAction.UPDATE_DATA_RESPONSE, "value": {
                message: "",
                statusCode: 0,
                data: null
            }
        });
    }

    return (
        <div className={styles["signin-locked"]}>
            <h6>{message}</h6>
            <p>{reason}</p>
            <table>
                <tr>
                    <td>Start date</td>
                    <td>{` : ${startTime}`}</td>
                </tr>
                <tr>
                    <td>End date</td>
                    <td>{` : ${endTime || "Permanent"}`}</td>
                </tr>
            </table>
            <Button type="button" onClick={handleOnClick}>Home</Button>
        </div>
    )
}