import React from "react";
import { Navbar } from "@stockraken/ui";
import { TopMenuToolbarGenerator } from "../TopMenuToolbarGenerator/TopMenuToolbarGenerator.component";
import { TopMenuSearchGenerator } from "../TopMenuSearchGenerator/TopMenuSearchGenerator.component";
import styles from "./TopMenu.module.scss";
import Lottie from "lottie-web";

export function TopMenu() {

    return (
        <Navbar>
            <Navbar.Body>
                <TopMenuSearchGenerator />
            </Navbar.Body>
            <Navbar.Tool>
                <TopMenuToolbarGenerator />
            </Navbar.Tool>
        </Navbar>
    )
}