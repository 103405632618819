import React from "react";
import styles from "./NotificationList.module.scss";
import {Icon} from "@stockraken/ui";
import { useAppSelector } from "../../../Redux/Hooks/Hooks";

export function NotificationList() {

    const { showNotificationList } = useAppSelector(state => state.appReducer);

    return (
        <div className={`${styles["notification-list"]} ${showNotificationList ? "right-0" : "-right-80"}`}>
            <div className={styles["notification-empty"]}>
                <Icon name="icon-bell" width="28px" height="28px" color="#9ca3af" />
                <h6>No Notification for you.</h6>
            </div>
        </div>
    )
}