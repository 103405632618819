import React from "react";
import { IKeyValue } from "@stockraken/ui/types";
import { Dropdown, FieldGroup, Input } from "@stockraken/ui";
import { useCountries, useCountryStates } from "../../../Core/Services/Country.service";
import { useAppDispatch } from "../../../Redux/Hooks/Hooks";
import { ISignupAdressProps } from "../../Props/Signup.props";
import { SignupAction } from "../../Redux/Actions/Signup.action";

export function SignupAdressForm(props: ISignupAdressProps) {

    const [countries] = useCountries();
    const [countryStates, setIso] = useCountryStates();

    const dispatch = useAppDispatch();

    const handleOnUpdate = (name: string, value: string) => {
        let json: IKeyValue<string> = {};
        json[name] = value;
        dispatch({ type: SignupAction.UPDATE_REQUEST_USER_INFORMATION_ADDRESS, value: json });
    }

    const handleOnUpdateCountry = (name: string, value: string) => {
        handleOnUpdate(name, value);
        setIso(value);
    } 

    return (
        <React.Fragment>
            <FieldGroup>
                <Dropdown
                    required={true}
                    placeholder="Choose a Country"
                    name="countryIso"
                    onUpdate={handleOnUpdateCountry}
                    value={props.countryIso}>
                    {
                        countries && 
                        countries.map((item, index) => (
                            <Dropdown.Item key={index} value={item.iso} text={item.name}>{item.name}</Dropdown.Item>
                        ))
                    }
                </Dropdown>
            </FieldGroup>
            <FieldGroup>
                <Dropdown
                    required={true}
                    placeholder="Choose a State"
                    name="stateAbbreviation"
                    onUpdate={handleOnUpdate}
                    value={props.stateAbbreviation}>
                    {
                        countryStates && countryStates.countryStates &&
                        countryStates.countryStates.map((item, index) => (
                            <Dropdown.Item key={index} value={item.abbreviation} text={item.name}>{item.name}</Dropdown.Item>
                        ))
                    }
                </Dropdown>
            </FieldGroup>
            <FieldGroup>
                <Input
                    type="text"
                    placeholder="Adress Line"
                    name={"street"}
                    autoFocus={true}
                    required={true}
                    minLength={8}
                    maxLength={150}
                    onUpdate={handleOnUpdate}
                    value={props.street} />
            </FieldGroup>
            <FieldGroup>
                <Input
                    type="text"
                    placeholder="Unit or App #"
                    name={"unity"}
                    autoFocus={true}
                    required={true}
                    minLength={1}
                    maxLength={3}
                    onUpdate={handleOnUpdate}
                    value={props.unity} />
            </FieldGroup>
            <FieldGroup>
                <Input
                    type="text"
                    placeholder="Postal Code"
                    name={"postalCode"}
                    autoFocus={true}
                    required={true}
                    minLength={1}
                    maxLength={8}
                    onUpdate={handleOnUpdate}
                    value={props.postalCode} />
            </FieldGroup>
        </React.Fragment>
    )
}
