import React from "react";
import { IHTMLButtonEvent } from "@stockraken/ui/types";
import { Modal } from "@stockraken/ui";
import { FilePrivacy } from "../FilePrivacy/FilePrivacy.component";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { FilePrivacyUiAction } from "../../Redux/Actions/FileUi.action";

export function FilePrivacyModal() {

    const dispatch = useAppDispatch();
    const { showFilePrivacyModal } = useAppSelector(state => state.fileUiReducer);

    const hideFilePrivacyModal = (event: IHTMLButtonEvent) => {
        dispatch({ type: FilePrivacyUiAction.TOGGLE_FILE_PRIVACY_MODAL, value: false });
    }

    return (
        <Modal disposeOnClose={false} show={showFilePrivacyModal} onClose={hideFilePrivacyModal}>
            <Modal.Header><h6>Edit Privacy</h6></Modal.Header>
            <Modal.Body>
                <FilePrivacy />
            </Modal.Body>
        </Modal>
    )
}