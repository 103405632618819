import React from "react";
import styles from "./FilePrivacyDropdown.module.scss"
import { Dropdown } from "@stockraken/ui";
import { IFilePrivacyDropdownProps } from "../../Props/FilePrivacy.props";

export function FilePrivacyDropdown({ filePrivacyId, handleOnUpdate }: IFilePrivacyDropdownProps) {

    const data = [
        { "id": "1", "text": "Private", "value": "00", "icon": "" },
        { "id": "2", "text": "Public", "value": "01", "icon": "" }
    ]

    return (
        <Dropdown
            required={true}
            className={styles["file-privacy-dropdown"]}
            name="filePrivacyId"
            onUpdate={handleOnUpdate}
            as="select"
            value={filePrivacyId}>
            {
                data &&
                data.map(item => (
                    <Dropdown.Item key={item.id} value={item.value} text={item.text}>
                        {item.text}
                    </Dropdown.Item>
                ))
            }
        </Dropdown>
    )
}