import React from "react";
import { HomeLayout } from "../../Layouts/HomeLayout/Home.layout";
import { AuthenticationRouterLayout } from "../../Layouts/AuthenticationRouterLayout/AuthenticationRouter.layout";

export function HomePage() {
    return (
        <AuthenticationRouterLayout.Required>
            <HomeLayout />
        </AuthenticationRouterLayout.Required>
    )
}