import React, { useState } from "react";
import { AppAction } from "../../../Redux/Actions/App.action";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/Hooks";
import { IFormData, IHTMLButtonEvent, IHTMLFormEvent, IHTMLInputEvent, IHTMLTextAreaEvent } from "@stockraken/ui/types";
import { Form, Input, Modal, Dropdown, TextArea, Button, Icon, FieldGroup } from "@stockraken/ui";
import { FileUploader } from "../../../Core/Components/FileUploader/FileUploader.component";
import { BugTypeService } from "../../Services/BugType.service";
import { BugService } from "../../Services/Bug.service";
import { ReportBugAction } from "../../Redux/Actions/ReportBug.action";
import i18next from "i18next";
import { IFileUploadResponse } from "../../../Core/Responses/IFileUpload.response";
import { IHttpResponseModel } from "../../Models/HttpResponse.model";
import { IReportBug } from "../../../Core/Responses/IReportBug.response";
import { FALSE } from "sass";

export function ReportBugModal() {

    const dispatch = useAppDispatch();
    const bugService = new BugService();
    const { bugTypes } = useAppSelector(state => state.ReportBugReducer);
    const { showReportBugModal } = useAppSelector(state => state.appReducer);
    const [attemp, setAttemp] = React.useState<number>(0);
    const acceptedExtensions = React.useRef<Array<string>>([".png", ".jpg", ".jpeg", ".webp", ".mp4", ".docx", ".pptx", ".pdf"]);
    const [title, setTitle] = useState<string>("");
    const [bugTypeId, setBugTypeId] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(false);
    const [response, setResponse] = useState<IHttpResponseModel<IReportBug> | null>(null);

    const onChangeTitle = (event: IHTMLInputEvent) => setTitle(event.target.value);
    const onChangeDescription = (event: IHTMLTextAreaEvent) => setDescription(event.target.value);
    const onUpdateBugType = (name: string, value: string) => setBugTypeId(value);

    const hideReportBugModal = (event: IHTMLButtonEvent) => {
        dispatch({ "type": AppAction.TOGGLE_REPORT_BUG_MODAL, value: false });
        setResponse(null);
        setDisabled(false);
        setTitle("");
        setBugTypeId("");
        setDescription("");
    }

    const onSubmit = (event: IHTMLFormEvent) => {
        event.preventDefault();
        setDisabled(true);
        setAttemp(attemp + 1);
    }

    const onUploadFile = (request: IFormData, onUploadProgress: (event: any, uuid: string) => any): Promise<IHttpResponseModel<IFileUploadResponse>> => {
        return new Promise((resolve, reject) => {
            bugService.InsertBug(request, onUploadProgress).then(response => {
                setResponse(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    React.useEffect(() => {
        BugTypeService.getBugTypes().then(result => {
            dispatch({ "type": ReportBugAction.SET_BUG_TYPES, value: result });
        });
    }, []);

    return (
        <Modal
            message={response?.message}
            messageLevel={response?.statusCode === 200 ? "success" : "danger"}
            disposeOnClose={true}
            show={showReportBugModal}
            onClose={hideReportBugModal}>
            <Form onSubmit={onSubmit}>
                <Modal.Header>
                    <div className="flex">
                        <Icon className="mr-2" name="icon-bug" width="17px" height="17px" color="#333" />
                        <h6>{i18next.t("translate-report-bug")}</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <FieldGroup>
                        <label>{i18next.t("translate-title")}</label>
                        <Input
                            name="title"
                            onChange={onChangeTitle}
                            value={title}
                            required
                            type="text"
                            maxLength={50}
                            placeholder={i18next.t("translate-short-title-describe-bug")} />
                    </FieldGroup>
                    <FieldGroup>
                        <label>{i18next.t("translate-type-bug")} *</label>
                        <Dropdown
                            name="bugType"
                            onUpdate={onUpdateBugType}
                            value={bugTypeId}
                            required
                            as="select"
                            placeholder={i18next.t("translate-choose-kind-bug")}>
                            {
                                bugTypes &&
                                bugTypes.map((item, index) => (
                                    <Dropdown.Item key={index} value={item.id} text={item.title}>{item.title}</Dropdown.Item>
                                ))
                            }
                        </Dropdown>
                    </FieldGroup>
                    <FieldGroup>
                        <label>{i18next.t("translate-description")}</label>
                        <TextArea
                            name="description"
                            onChange={onChangeDescription}
                            value={description}
                            required
                            rows={5}
                            maxLength={500}
                            placeholder={i18next.t("translate-short-description-bug")} />
                    </FieldGroup>
                    <FileUploader
                        acceptedExtensions={acceptedExtensions.current}
                        onUploadFile={onUploadFile}
                        autoUpload={false}
                        attemp={attemp}
                        filePrivacyId="00"
                        size="base"
                        dataExtension={{
                            title: title,
                            bugTypeId: bugTypeId,
                            description: description
                        }} />
                </Modal.Body>
                <Modal.Footer className="flex">
                    <SendButton disabled={disabled} />
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const SendButton: React.FC<{ disabled: boolean }> = ({ disabled }) => {
    return (
        <Button type={"submit"} disabled={disabled} className="button-main w-1/4 ml-auto">
            {
                disabled ?
                    <React.Fragment>
                        <Icon name="icon-spin" width="20px" height="20px" color="white" className="animate-spin -ml-1 mr-3" />
                        <React.Fragment>{`${i18next.t("translate-sending")}...`}</React.Fragment>
                    </React.Fragment>
                    :
                    <React.Fragment>{i18next.t("translate-send")}</React.Fragment>
            }
        </Button>
    )
}