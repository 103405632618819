import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { appReducer } from '../Reducers/App.reducer';
import { signinReducer } from '../../Identity/Redux/Reducers/Signin.reducer';
import { signupReducer } from '../../Identity/Redux/Reducers/Signup.reducer';
import { countryReducer } from '../../Core/Redux/Reducers/Country.reducer';
import { genderReducer } from '../../Core/Redux/Reducers/Gender.reducer';
import { fileUploadReducer } from '../../Core/Redux/Reducers/FileUpload.reducer';
import { fileSignReducer } from '../../Core/Redux/Reducers/FileSign.reducer';
import { fileUiReducer } from '../../Core/Redux/Reducers/FileUi.reducer';
import { UserReducer } from '../../Identity/Redux/Reducers/User.reducer';
import { ReportBugReducer } from '../../Common/Redux/Reducers/ReportBug.reducer';
import { RoomReducer } from '../../Communication/Redux/Reducers/Room.reducer';
import { LiveStreamReducer } from '../../Communication/Redux/Reducers/LiveStream.reducer';
import { UserUiReducer } from '../../Identity/Redux/Reducers/UserUi.reducer';

const reducers = combineReducers({
    appReducer,
    signinReducer,
    signupReducer,
    countryReducer,
    genderReducer,
    fileUploadReducer,
    fileSignReducer,
    fileUiReducer,
    UserReducer,
    ReportBugReducer,
    RoomReducer,
    LiveStreamReducer,
    UserUiReducer
});

const store = configureStore({
    reducer: reducers
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store };