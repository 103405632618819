export const api = {
    core: {
        GetCountries: "/api/Country",
        GetCountryStatesByIso: "/api/CountryState",
        GetSignFiles: "/api/FileSign/GetFiles",
        GetSignFileById: "/api/FileSign/GetFile",
        SignFile: "/api/FileSign/SignFile",
        SignFiles: "/api/FileSign/SignFiles",
        UpdateSignFilePrivacy: "/api/FileSign/UpdateFilePrivacy",
        DownloadSignFile: "/api/FileSign/DownloadFile",
        RemoveSignFile: "/api/FileSign/RemoveFile",
        RemoveSignFiles: "/api/FileSign/RemoveFiles",
        GetUploadFiles: "/api/FileUpload/GetFiles",
        UploadFile :  "/api/FileUpload/UploadFile",
        UploadFiles : "/api/FileUpload/UploadFiles",
        UpdateUploadFilePrivacy :  "/api/FileUpload/UpdateFilePrivacy",
        DownloadUploadFile:  "/api/FileUpload/DownloadFile",
        RemoveUploadFile:  "/api/FileUpload/RemoveFile",
        RemoveUploadFiles:  "/api/FileUpload/RemoveFiles",
        GetGenders: "/api/Gender/GetAllGenders",
        SignedFilePage: "/signed-files",
        UploadedFilePage: "/uploaded-files",
        FileVerificationPage: "/verification/:id"
    }
}