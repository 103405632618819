import React from "react";
import { FileSignTable } from "../../Components/FileSignTable/FileSignTable.component";
import { ContainerLayout } from "../../../Common/Layouts/ContainerLayout/ContainerLayout";
import { AppLayout } from "../../../Common/Layouts/AppLayout/AppLayout";

export function FileSignLayout() {
    return (
        <AppLayout>
            <ContainerLayout>
                <FileSignTable />
            </ContainerLayout>
        </AppLayout>
    )
}