import React from "react";
import { Button } from "@stockraken/ui";
import { ISigninActivationProps } from "../../Props/Signin.props";
import styles from "./SigningActivation.module.scss";

export function SigningActivation({firstName, lastName, message, email, id}: ISigninActivationProps) {
    return(
        <div className={styles["signin-activation"]}>
            <p>{`Hi, ${firstName} ${lastName}`}</p>
            <p>{message}</p>
            <b>{email}</b>
            <Button type="button" className="mt-5">
                Send Again !
            </Button>
        </div>
    )
}