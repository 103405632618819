import { useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { HttpStatus } from "@stockraken/ui/enumerations";
import { axiosErrorHandler } from "@stockraken/ui/handlers";
import { IUserAuthenticationResponse } from "../Responses/UserAuthentication.response";
import { api } from "../Services.api";
import { CookieService } from "./Cookie.service";
import { IUserRegistrationResponse } from "../Responses/UserRegistration.response";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks/Hooks";
import { IUserRegistrationRequest } from "../Requests/UserRegistration.request";
import { IUserAuthenticationRequest } from "../Requests/UserAuthentication.request";
import { UserAction } from "../Redux/Actions/User.action";
import { IUserInformation } from "../Responses/UserInformation.response";
import { IUserUpdatePasswordRequest } from "../Requests/UserUpdatePassword.request";
import { IHttpResponseModel } from "../../Common/Models/HttpResponse.model";

const http = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_IDENTITY,
    headers: {
        "Authorization": `Bearer ${Cookies.get("stockraken-jwt")}`
    }
});

export class UserService {

    public static authenticate(request: IUserAuthenticationRequest) {
        return new Promise<IUserAuthenticationResponse>((resolve, reject) => {
            http.post<IUserAuthenticationResponse>(api.identity.Authenticate, request).then(result => {
                if (result.data.statusCode === HttpStatus.Status200OK && result.data.data?.accessToken) {
                    CookieService.setAccessTokenCookie(result.data.data?.accessToken).then(jwt => {
                        window.location.replace("/");
                    }).catch(error => {
                        console.error(error);
                    });
                }
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public static register(request: IUserRegistrationRequest) {
        return new Promise<IUserRegistrationResponse>((resolve, reject) => {
            http.post<IUserRegistrationResponse>(api.identity.Register, request).then(result => {
                resolve(result.data);
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public static getUserInformationByToken() {

        return new Promise<IUserInformation | null>((resolve, reject) => {
            http.get<IUserInformation | null>(api.identity.GetUserInformation).then(result => {
                if (result.status === HttpStatus.Status200OK) {
                    resolve(result.data);
                }
            }).catch((error: any) => {
                axiosErrorHandler(error);
            });
        });
    }

    public static updatePassword(request: IUserUpdatePasswordRequest) {
        return new Promise<IHttpResponseModel>((resolve, reject) => {
            http.post<IHttpResponseModel>(api.identity.UpdatePassword, request).then(response => {
                resolve(response.data);
            }).catch((error: any) => {
                reject(error.response.data);
                axiosErrorHandler(error);
            });
        });
    }
}

export function useUserService() {

    const dispatch = useAppDispatch();
    const data = useAppSelector(state => state.UserReducer);

    useEffect(() => {
        if (!data) {
            UserService.getUserInformationByToken().then(result => {
                dispatch({ type: UserAction.UPDATE_DATA, value: result });
            });
        }
    }, [data, dispatch]);

    return { data } as const;
}