import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Icon } from "@stockraken/ui";
import { CookieService } from "../../Services/Cookie.service";
import { useUserService } from "../../Services/User.service";
import { UserIconLetter } from "../UserIconLetter/UserIconLetter.component";
import styles from "./UserDropdown.module.scss";
import { MenuTogglerContext } from "@stockraken/ui";
import { MenuItemType } from "../../../Common/Types/Menu";
import { IUserInformation } from "../../Responses/UserInformation.response";
import { UserUiAction } from "../../Redux/Actions/UserUi.action";
import { useAppDispatch } from "../../../Redux/Hooks/Hooks";

export function UserDropdown() {

    const dispatch = useAppDispatch();
    const showMenu = useContext<boolean>(MenuTogglerContext);
    const navigate = useNavigate();
    const { data } = useUserService();

    const menu = React.useRef<Array<MenuItemType>>([
        { "id": 1, "text": "My Profil", "value": "my-profil", "icon": "icon-user", "disabled": true },
        { "id": 2, "text": "Settings", "value": "settings", "icon": "icon-settings", "disabled": true },
        { "id": 3, "text": "Update Password", "value": "update-password", "icon": "icon-password", "disabled": false },
        { "id": 4, "text": "About Us", "value": "about-us", "icon": "icon-info", "disabled": true },
        { "id": 5, "text": "Sign out", "value": "disconnect", "disabled": false }
    ]);

    const handleOnUpdate = (name: string, value: string) => {
        switch (value) {
            case "disconnect":
                CookieService.removeAccessTokenCookie().then(() => {
                    navigate("/signin");
                });
                break;
            case "update-password":
                dispatch({ type: UserUiAction.TOGGLE_USER_UPDATE_PASSWORD_MODAL, value: true });
                break;
            default:
                break;
        }
    }

    return (
        <div className={styles["user-dropdown"]}>
            {
                data &&
                <Dropdown
                    required={true}
                    as={showMenu ? "select" : undefined}
                    position={showMenu ? "top" : "right"}
                    element={<UserDropdownHandler data={data} showMenu={showMenu} />}
                    name="stateAbbreviation"
                    onUpdate={handleOnUpdate}
                    variant="link"
                    value={""}>
                    {
                        menu.current &&
                        menu.current.map((item, index) => (
                            <Dropdown.Item key={index} value={item.value} text={item.text} disabled={item.disabled || false}>
                                <div className="flex items-center">
                                    {
                                        item.icon &&
                                        <Icon name={item.icon} width="15px" height="15px" color="#333" />
                                    }
                                    <div className={item.icon ? "ml-3" : "ml-0"}>{item.text}</div>
                                </div>
                            </Dropdown.Item>
                        ))
                    }
                </Dropdown>
            }
        </div>
    )
}

const UserDropdownHandler: React.FC<{ data: IUserInformation, showMenu: boolean }> = ({ data, showMenu }) => {
    return (
        <div className={styles["user-dropdown-handler"]}>
            {
                data &&
                <React.Fragment>
                    {
                        showMenu && <label>{`${data.firstName} ${data.lastName}`}</label>
                    }
                    <UserIconLetter firstName={data.firstName} lastName={data.lastName} />
                </React.Fragment>
            }
        </div>
    )
}