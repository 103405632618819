import { FileSignUiAction, FilePrivacyUiAction, FileUploadUiAction, FileUiAction } from "../Actions/FileUi.action";
import { IFileUiState } from "../../States/IFileUi.state";

const initialState: IFileUiState = {
    showFilePrivacyModal: false,
    showFileSignRemoveModal: false,
    showFileSignModal: false,
    showFileUploadRemoveModal: false,
    showFileUploadModal: false,
    progress: 0,
    search: ""
};

export function fileUiReducer(state = initialState, action: any) {

    let nextState: IFileUiState;

    switch (action.type) {
        case FileUiAction.SET_SEARCH:
            nextState = {
                ...state,
                search: action.value
            }
            return nextState || state;
        case FilePrivacyUiAction.TOGGLE_FILE_PRIVACY_MODAL:
            nextState = {
                ...state,
                showFilePrivacyModal: action.value || !state.showFilePrivacyModal
            }
            return nextState || state;
        case FileSignUiAction.TOGGLE_FILE_REMOVE_MODAL:
            nextState = {
                ...state,
                showFileSignRemoveModal: action.value || !state.showFileSignRemoveModal
            }
            return nextState || state;
        case FileUploadUiAction.TOGGLE_FILE_REMOVE_MODAL:
            nextState = {
                ...state,
                showFileUploadRemoveModal: action.value || !state.showFileUploadRemoveModal
            }
            return nextState || state;
        case FileUploadUiAction.TOGGLE_FILE_UPLOAD_MODAL:
            nextState = {
                ...state,
                showFileUploadModal: action.value || !state.showFileUploadModal
            }
            return nextState || state;
        case FileUploadUiAction.TOGGLE_FILE_SIGN_MODAL:
            nextState = {
                ...state,
                showFileSignModal: action.value || !state.showFileSignModal
            }
            return nextState || state;
        default:
            return state;
    }
}