import React from "react";
import { ISigninAuthenticatorProps } from "../../Props/Signin.props";
import styles from "./SigninAuthenticator.module.scss";

export function SigninAuthenticator({ message, authenticationCode, userId }: ISigninAuthenticatorProps) {

    const getAuthCode = (code: string) => {
        return code.toUpperCase().split("-")[0];
    }

    const getAuthCodeMemo = React.useMemo(() => getAuthCode(authenticationCode), [authenticationCode]);

    return (
        <div className={styles["signin-authenticator"]}>
            <h3>Authentication Required</h3>
            <p>{message}</p>
            <p>Code : <b>{getAuthCodeMemo}</b></p>
        </div>
    )
}