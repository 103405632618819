import React from "react";
import { SignupForm } from "../../Components/SignupForm/SignupForm.component";
import styles from "./Signup.module.scss";
import { AuthenticationRouterLayout } from "../../Layouts/AuthenticationRouterLayout/AuthenticationRouter.layout";

export function SignupPage() {
    return (
        <AuthenticationRouterLayout.Auth>
            <div className={styles["signup-page-app"]}>
                <SignupForm />
            </div>
        </AuthenticationRouterLayout.Auth>
    )
}