import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./FileUploader.module.scss";
import { DragAndDrop, Icon, Badge } from "@stockraken/ui";
import { IHTMLButtonEvent, IFormData } from "@stockraken/ui/types";
import { IFileUploadProgressProps } from "../../Props/FileUpload.props";
import _ from "lodash";

export const FileUploader = React.memo(({ acceptedExtensions, filePrivacyId = "00", size = "large", autoUpload = false, attemp = 0, onUploadFile, dataExtension }: IFileUploadProgressProps) => {

    const [currentFormDataList, setCurrentFormDataList] = useState<Array<IFormData>>();
    const uploadLocked = useRef<boolean>(false);

    const onDropFiles = (formDataList: Array<IFormData>) => {
        setCurrentFormDataList(formDataList);
    };

    const onCloseBadge = (event: IHTMLButtonEvent) => {
        let remainingFiles = currentFormDataList?.filter(file => file.uuid !== event.currentTarget.value);
        setCurrentFormDataList(remainingFiles);
    }

    const updateProgress = useCallback((event: any, uuid: string) => {
        if (event && event.loaded && event.total && event.total !== 0) {
            setCurrentFormDataList(previousFormDataList => {
                let nextFromDataList = _.cloneDeep(previousFormDataList)!;
                nextFromDataList.find(item => item.uuid === uuid)!.progress = (event.loaded / event.total) * 100;
                return nextFromDataList;
            });
        }
    }, []);

    const uploadFileRecursively = useCallback((formDataList: Array<IFormData>) => {
        let item = formDataList.pop();
        if (item?.formData && uploadLocked.current && onUploadFile) {
            item.formData.append("FilePrivacyId", filePrivacyId);
            onUploadFile(item, updateProgress).then(response => {
                if (formDataList.length === 0) {
                    uploadLocked.current = false;
                }
                uploadFileRecursively(formDataList);
            });
        }
    }, [onUploadFile, updateProgress, filePrivacyId]);

    useEffect(() => {
        if (currentFormDataList && !uploadLocked.current && (autoUpload || attemp > 0)) {
            uploadLocked.current = true;
            let nextFormDataList = _.cloneDeep(currentFormDataList);
            if (dataExtension) {
                Object.keys(dataExtension).forEach(key => {
                    nextFormDataList.at(0)?.formData.set(key, dataExtension[key]);
                });
            }
            uploadFileRecursively(nextFormDataList);
        }
    }, [currentFormDataList, uploadFileRecursively, autoUpload, attemp, dataExtension]);

    return (
        <div className={styles["uploader"]}>
            <DragAndDrop onDropFiles={onDropFiles} acceptedExtensions={acceptedExtensions}>
                <div className={`${styles["uploader-dragdrop"]} ${styles[size]}`}>
                    <Icon name="icon-folder-file-colored" height="40px" width="40px" />
                    <span className={styles["uploader-message"]}>Drag & drop or <b>browse</b>.</span>
                </div>
            </DragAndDrop>
            {
                Array.isArray(currentFormDataList) && currentFormDataList.length > 0 &&
                <div className="mt-3">
                    {
                        currentFormDataList.map((item, index) => (
                            <Badge key={index} className="mb-2 mr-2" progress={item.progress} onClose={onCloseBadge} value={item.uuid}>
                                <div className="flex items-center">
                                    <Icon name="icon-file" height="14px" width="14px" color="#333" />
                                    <div className="ml-2">{item.fileName}</div>
                                </div>
                            </Badge>
                        ))
                    }
                </div>
            }
        </div>
    )
});