import React from "react";
import styles from "./StreamingDisplay.module.scss";
import { useAppSelector } from "../../../Redux/Hooks/Hooks";
import { VideoStreaming } from "../../Features/Video/Video.component";

export function StreamingDisplay() {

    const url = React.useRef<string>("http://localhost/stream/BC13E67D-D72D-49B1-96C3-FA74C4219128/index.m3u8");
    const { title, description, streamingStarted } = useAppSelector(state => state.LiveStreamReducer);

    return (
        <div className={styles["streaming-display"]}>
            <VideoStreaming
                url={url.current}
                autoPlay={true}
                muted
                poster="/img/streaming-down.png"
            />
            <h2>{title}</h2>
            <p>{description}</p>
        </div>
    )
}