import Cookies from "js-cookie";

export class CookieService {

    public static setAccessTokenCookie(jwt: string) {
        return new Promise((resolve, reject) => {
            if (jwt && Cookies.get("stockraken-jwt") === undefined) {
                Cookies.set("stockraken-jwt", jwt, { expires: 365 });
            }

            let value = Cookies.get("stockraken-jwt");

            if (value) {
                resolve(value)
            } else {
                reject(new Error("Cookie was not created."))
            }
        })
    }

    public static removeAccessTokenCookie() {
        return new Promise<void>((resolve, reject) => {
            Cookies.remove("stockraken-jwt");
            let value = Cookies.get("stockraken-jwt");

            if (!value) {
                resolve()
            } else {
                reject(new Error("Error has occured when trying to remove cookie."))
            }
        })
    }

}