import React from 'react';
import { AppRouter } from './AppRouter';
import { i18nextInitialization } from './Translations/Translation';

function App() {

  const [isReady, setReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    i18nextInitialization().then(t => {
      setReady(true);
    });
  }, []);

  return (
    isReady ? <AppRouter /> : <></>
  );
}

export { App };
