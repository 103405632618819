import React from "react";
import styles from "./SigninAnimation.module.scss";
import Lottie from "lottie-web";

export function SigninAnimation() {

    const octopusRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (octopusRef.current) {
            const instance = Lottie.loadAnimation({
                container: octopusRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/lottie/trimmed-cube.json'
            });
            return () => instance.destroy();
        }
    }, [octopusRef])

    return (
        <div className={styles["signin-animation"]}>
            <div ref={octopusRef} className={styles["cube-animation"]}></div>
            <h1>StocKraken</h1>
        </div>
    )
}