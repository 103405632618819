import React from "react";
import { FileUploadTable } from "../../Components/FileUploadTable/FileUploadTable.component";
import { ContainerLayout } from "../../../Common/Layouts/ContainerLayout/ContainerLayout";
import { AppLayout } from "../../../Common/Layouts/AppLayout/AppLayout";

export function FileUploadedLayout() {

    return (
        <AppLayout>
            <ContainerLayout>
                <FileUploadTable />
            </ContainerLayout>
        </AppLayout>
    )
}